import React, { useEffect, useState } from "react";
import Header from "../components/header";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import axios from "axios";
import Cookies from "js-cookie";
import Mpermissions from "../components/userDisplaytabs/m_permissions";
import CompanydisplayTabs from "../components/userDisplaytabs/companydisplayTabs";
import BranchDisplayTabs from "../components/userDisplaytabs/branchDisplayTabs";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { NavigateBefore } from "@mui/icons-material";
import DeleteCanidateDrawer from "../components/deleteCandidateDrawer";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: "none",
  padding: theme.spacing(0.5),
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3, px: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const UserDetails = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  const [basicInfo, setBasicInfo] = useState([{}]);
  const [accessType, setAccessType] = useState([{}]);
  const [displayCompanyInfo, setDisplayCompanyInfo] = useState([]);
  const [displayBranchInfo, setDisplayBranchInfo] = useState([]);

  const { id } = useParams();
  const data = { userId: id };
  const [searchParams, setSearchParams] = useSearchParams({
    tab: "companyInfo",
  });

  const getUserInfoDetails = async () => {
    try {
      const response = await axios.get(`/user-info/${id}`, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });
      setUserInfo(response.data.message);
      setBasicInfo(response.data.message.basic_info);
      setAccessType(response.data.message.access_type);
      setDisplayCompanyInfo(response.data.message.companiesList);
      setDisplayBranchInfo(response.data.message.companiesBranchList);
    } catch (error) {
      alert("Page is not found");
      navigate("/Users?page=1");
      console.error(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getUserInfoDetails();
  }, []);

  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab === "b_info") {
      setValue(0);
    } else if (tab === "menu_permissions") {
      setValue(1);
    } else if (tab === "c_info") {
      setValue(2);
    } else if (tab === "cb_info") {
      setValue(3);
    }
  }, [searchParams]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setSearchParams({ tab: newValue === 0 && "b_info" });
    } else if (newValue === 1) {
      setSearchParams({ tab: newValue === 1 && "menu_permissions" });
    } else if (newValue === 2) {
      setSearchParams({ tab: newValue === 2 && "c_info" });
    } else if (newValue === 3) {
      setSearchParams({ tab: newValue === 3 && "cb_info" });
    }
  };

  const { firstName, lastName, email } = basicInfo;
  const handleBack = () => {
    const previousUrl = sessionStorage.getItem('UserpreviousUrl');
    console.log(previousUrl);

    if (previousUrl) {
      // Parse the URL
      const url = new URL(previousUrl, window.location.origin); // Ensure URL is absolute
      const path = url.pathname; // Extract path
      const queryParams = url.search; // Extract query parameters

      // Navigate using path and query parameters
      navigate(`${path}${queryParams}`);

      // Clean up
      sessionStorage.removeItem('UserpreviousUrl');
    }
    else {
      navigate('/Users?page=1');
    }
  };
  //delete candidate functionality from here starting
  const [open, setOpen] = useState(false);
  const [acknowledged, setAcknowledged] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [underAssignRole, setUnderAssignRole] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");

  const handleAcknowledgeChange = (event) => {
    setAcknowledged(event.target.checked);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAcknowledged(false);
    setUnderAssignRole();
  };

  const fetchChildAuthority = async () => {
    try {
      const response = await axios.post("/child-users", {
        user_role_id: basicInfo?.role_id,
        user_id: id
      }, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });
      if (response.data.status === 200) {
        const list = response.data.message;
        setRoleList(list);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (open) {
      fetchChildAuthority();
    }
    else {
      return;
    }
  }, [open])

  const handleunderAssignRole = (event) => {
    const selectedRoleId = event.target.value;
    const selectedRole = roleList.find(roleItem => roleItem.id === selectedRoleId);
    setUnderAssignRole(selectedRole);
  }
  const handleDelete = () => {
    if (!(acknowledged && (underAssignRole?.id))) {
      return;
    } else {
      try {
        axios.post(`/delete-user/${id}`, {
          under_assigned_user_id: underAssignRole?.id,
          role_id: basicInfo?.role_id
        }, {

          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        });
        setSnackbarMessage("User Deleted Successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate(`/Users?page=1`);

        }, 800);
      } catch (err) {
        console.log(err);
        setAcknowledged(false);
        setUnderAssignRole({});
        setSnackbarMessage("User Deletion Failed");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } finally {
        setOpen(false);
        setAcknowledged(false);
      }
    }
  };
  return (
    <Box className="pageWrapper">
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
        >
          <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
            <Box onClick={handleBack}>
              <ArrowBackIosIcon sx={{ cursor: "pointer" }} />
            </Box>
            <Box>
              <Avatar
                style={{
                  width: 90,
                  height: 90,
                  borderRadius: "4px",
                  fontSize: "3rem",
                }}
              />
            </Box>
            <Box sx={{ px: 3 }}>
              <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                {firstName ? firstName : "NA"} {lastName}
              </Typography>
              <Typography variant="subtitle1" color={"gray"}>
                {basicInfo.role_name ? basicInfo.role_name : "NA"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              variant="h3"
              component="h1"
            >
              <Box display={"flex"} gap={2} alignItems={"center"}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() =>
                    navigate(`/addUser?tab=basicInfo&user_id=${id}&edit=1`)
                  }
                  startIcon={<EditIcon />}
                >
                  Edit User
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleOpen}
                  startIcon={<DeleteOutlineOutlinedIcon />}
                >
                  Delete User
                </Button>
                <DeleteCanidateDrawer
                  open={open}
                  roleList={roleList}
                  handleunderAssignRole={handleunderAssignRole}
                  underAssignRole={underAssignRole}
                  usersection={true}
                  basicInfo={basicInfo}
                  handleClose={handleClose}
                  acknowledged={acknowledged}
                  handleDelete={handleDelete}
                  handleAcknowledgeChange={handleAcknowledgeChange}
                />
              </Box>
            </Box>
          </Grid>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
          >
            <Alert
              onClose={() => setSnackbarOpen(false)}
              severity={snackbarSeverity} // Adjust severity as needed              } // Adjust severity as needed
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Grid>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Divider sx={{ borderWidth: 1 }} />
      </Box>
      <Box sx={{ mt: 3.5 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            textColor={"primary"}
            indicatorColor={"primary"}
            onChange={handleChange}
            aria-label="icon label tabs example"
          >
            <Tab
              label="Basic Info"
              sx={{ letterSpacing: "1.25px", px: 4 }}
              {...a11yProps(0)}
            />
            <Tab
              label="Role Permissions"
              sx={{ letterSpacing: "1.25px", px: 4 }}
              {...a11yProps(1)}
            />
            <Tab
              label="Company"
              sx={{ letterSpacing: "1.25px", px: 4 }}
              {...a11yProps(2)}
            />
            <Tab
              label="Branches"
              sx={{ letterSpacing: "1.25px", px: 4 }}
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box sx={{ display: "flex", gap: { xs: 1, sm: 2, md: 15, lg: 15 } }}>
            <Box
              sx={{
                color: "gray",
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography variant="body1" fontSize={"18px"}>
                First Name
              </Typography>
              <Typography variant="body1" fontSize={"18px"}>
                Last Name
              </Typography>
              <Typography variant="body1" fontSize={"18px"}>
                Email
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography
                variant="body1"
                fontSize={"18px"}
                sx={{ textTransform: "capitalize" }}
              >
                {" "}
                {firstName ? firstName : "NA"}{" "}
              </Typography>
              <Typography
                variant="body1"
                fontSize={"18px"}
                sx={{ textTransform: "capitalize" }}
              >
                {" "}
                {lastName ? lastName : "NA"}{" "}
              </Typography>
              <Typography variant="body1" fontSize={"18px"}>
                {" "}
                {email ? email : "NA"}{" "}
              </Typography>
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Mpermissions rolepermissions={basicInfo} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <CompanydisplayTabs companiesList={displayCompanyInfo} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <BranchDisplayTabs companiesBranchList={displayBranchInfo} />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default UserDetails;
