import {
  Alert,
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  Grow,
  MenuItem,
  MenuList,
  Modal,
  Paper,
  Popper,
  Skeleton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ShareIcon from "@mui/icons-material/Share";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CandidateInfo from "./candidateInfo";
import CandidateActivities from "./candidateActivities";
import axios from "axios";
import { useSelector } from "react-redux";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import Avatar from "@mui/material/Avatar";
import { useParams } from "react-router-dom";
import Questionnairedata from "./Questionnairedata";
import Questionpop from "./Questionpop";
import CandidateEditableProfile from "./CandidateEditableProfile";
import DialogBox from "./DialogBox";
import Cookies from "js-cookie";
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';

// const baseURL = process.env.REACT_APP_BASEURL;
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
};

const options = [
  "Create a merge commit",
  "Squash and merge",
  "Rebase and merge",
];
const CandidateDetails = ({
  isOpen,
  candidatedrawerflag,
  onClose,
  selectedRowData,
  verifiedstatus,
  setrefreshagain,
  refreshagain,
  issearchnavbar,
  opennew,
  setOpennew,
  activitydisable,
  setactivitydisable,
  responsehide,
  Shortlisted,
  questionrequired,
  setApplicanOpen,
  isApplicanOpen,
  testingdata,
  Jobstatus,
}) => {
  const PermissionStatus = useSelector((state) => state.user.permissions);
  const AddCandidatePermission = PermissionStatus["100"];
  const [value, setValue] = React.useState(0);
  const [candidatedetails, setcandidatedetails] = useState([]);
  const getToken = useSelector((state) => state.user.token);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [status, setstatus] = useState([]);
  // const [open, setOpen] = React.useState(false);
  const [addtoJob, setAddtoJob] = useState(false);
  const [jobOrder, setJobOrder] = useState("");
  const [joborderIds, setJoborderIds] = useState();
  const [inputValueJobOrder, setInputValueJobOrder] = useState("");
  const [jobOrderOptions, setJobOrderOptions] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [activitesrefresh, setactivitesrefresh] = useState(false);
  const [newvalues, setnewvalue] = useState(0);
  const [statusupdate, setstatusupdate] = useState(selectedRowData?.status);
  const [questionData, setQuestionData] = useState([]);
  const [questionrequiredflag, setquestionrequiredflag] = useState();
  const [quesOpenPopup, setQuesOpenPopup] = useState(false);
  const [quesAnsArray, setQuesAnsArray] = useState([]);
  const [textFields, setTextFields] = useState({});
  const [radioValues, setRadioValues] = useState({});
  const [closedrawer, setclosedrawer] = useState(isOpen);
  const resumeRef = useRef(null);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const title = "Change Status";
  // Comment box States here
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [comment, setComment] = useState("");

  // Comment box function here
  const openDialog = () => {
    setIsDialogOpen(true);
  };

  // Comment box function here
  const closeDialog = () => {
    setIsDialogOpen(false);
    setComment("");
  };

  const update_date = testingdata?.filter(
    (e) => e?.id === selectedRowData?.id
  )[0]?.date_modified;
  // console.log("amresh", testingdata.filter((e) => e.id === selectedRowData.id)[0].date_modified);

  // Comment box function here
  const saveCommentAndStatus = () => {
    const obj = {
      id: selectedRowData.id,
      date_modified: update_date,
    };
    axios
      .post(
        `change-candidate-job-status`,
        {
          candidate_ids: [obj],
          job_id: selectedRowData.joborder_id,
          status: selectedStatus,
          comment: comment,
        },
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          handleSnackbarOpen("Candidate Status Changed Successfully");
          setrefreshagain(!refreshagain);
          setactivitesrefresh(!activitesrefresh);
          setnewvalue(1);
          setstatusupdate(selectedStatus);
        }
      })
      .catch((err) => {
        // console.log("err");
        handleSnackbarOpen(err.response.data.error);
      });
    closeDialog();
  };

  const { id } = useParams();
  // console.log("id", id);
  const handleSnackbarOpen = (message) => {
    // console.log("Snackbar message:", message);
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleClick = () => {
    // console.info(`You clicked ${options[selectedIndex]}`, "test");
  };

  // const handleClose = (event) => {
  //   if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //     return;
  //   }
  //    setOpen(false);
  // };

  const handleClose = () => {
    setAddtoJob(false);
    setJobOrder("");
  };

  const handleToggle = () => {
    setOpennew((prevOpen) => !prevOpen);
  };

  const handleMenuItemClick = (event, index, item) => {
    setSelectedIndex(index);
    setSelectedStatus(item);
    openDialog();
    setOpennew(false);
    // saveCommentAndStatus(item.status);
  };

  useEffect(() => {
    axios
      .get(`candidate/job-status`, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      })
      .then((response) => {
        setstatus(response.data.message);
        setOpennew(false);
        // console.log("hello", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const candidate_id = selectedRowData?.id;
    if (!candidate_id) return;
    axios
      .post(
        `candidate/details/${candidate_id}?isEditableProfile=false`,
        {
          jobId: id,
        },
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      )
      .then((response) => {
        // console.log("value kya hai",newvalues)

        setValue(newvalues);

        setcandidatedetails(response.data.message);
        setOpennew((prevOpen) => {
          // Set to false if the drawer is open, otherwise toggle the value
          return isOpen ? false : !prevOpen;
        });
      })
      .catch((err) => { });
  }, [isOpen, activitesrefresh, newvalues]);

  // console.log("activitesrefresh",activitesrefresh)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handlequestionary = () => {
  //   setQuesOpenPopup(true);
  // };

  const validate = () => {
    let isValid = true;
    // console.log("gaya validate ma")
    let updateQuesArr = [...quesAnsArray];
    updateQuesArr.forEach((item, index) => {
      if (item.question_types === 1) {
        if (item.answer_text === "") {
          item.error = "This field is required";
          isValid = false;
        } else if (item.answer_text.length >= 501) {
          item.error = "Maximum 500 characters allowed";
          isValid = false;
        } else {
          item.error = null;
        }
      } else if (item.question_types === 2) {
        if (item.answer_text === "") {
          item.error = "This field is required";
          isValid = false;
        } else if (item.answer_text.length >= 13) {
          item.error = "Maximum 12 digits allowed";
          isValid = false;
        } else {
          item.error = null;
        }
      } else if (item.question_types === 3) {
        if (item.answer_text === "") {
          item.error = "This field is required";
          isValid = false;
        } else {
          item.error = null;
        }
      }
    });
    setQuesAnsArray(updateQuesArr);
    return isValid;
  };

  const handleQuessummit = () => {
    if (!validate()) {
      console.log("validation failed");
      return;
    }

    // console.log("gaya handlequessummit ma")
    axios
      .post(
        `bulk-add/job?questionnaire=true`,
        {
          candidate_ids: [selectedRowData.id],
          job_id: joborderIds ? joborderIds : "" || id ? id : "",
          quesAnsArray: quesAnsArray,
          isShortlisted: 1,
        },
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          handleSnackbarOpen("Candidate Added Successfully");
          // console.log(response);
          setAddtoJob(false);
          setJobOrder("");
          setTextFields({});
          setRadioValues({});
          setQuesOpenPopup(false);
          setTimeout(() => {
            setApplicanOpen(false);
          }, 500);
        } else {
          handleSnackbarOpen("The candidates have already applied");
          setAddtoJob(false);
          setJobOrder("");
          // isOpen(false)
          setQuesOpenPopup(false);
          setTimeout(() => {
            setApplicanOpen(false);
          }, 500);
        }
      })
      .catch((err) => {
        console.log("error");
        handleSnackbarOpen(err.response.data.error);
        setQuesOpenPopup(false);
        setAddtoJob(false);
        setJobOrder("");
      });
  };

  const handlequestionairequired = () => {
    if (questionrequired == 1) {
      // console.log("38444444444444444444444444444444444444")
      setQuesOpenPopup(true);
    } else {
      handleAddCandidates();
    }
  };

  // console.log("Shortlisted",Shortlisted)

  // console.log("questionrequired",questionrequired)

  const handleAddCandidates = () => {
    // console.log("ye wali chaliiiiiii379999999999")
    axios
      .post(
        `bulk-add/job`,
        {
          candidate_ids: [selectedRowData.id],
          job_id: joborderIds ? joborderIds : "" || id ? id : "",
          quesAnsArray: [],
          isShortlisted: Shortlisted ? 1 : 0,
        },
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          handleSnackbarOpen("Candidate Added Successfully");
          // console.log(response);
          setAddtoJob(false);
          setJobOrder("");
          if (Shortlisted || onClose) {
            setTimeout(() => {
              setApplicanOpen(false);
            }, 500);
          }
        } else {
          handleSnackbarOpen("The candidates have already applied");
          setAddtoJob(false);
          setJobOrder("");
        }
      })
      .catch((err) => {
        console.log("error");
        handleSnackbarOpen(err.response.data.error);
        setAddtoJob(false);
        setJobOrder("");
      });
  };

  useEffect(() => {
    // console.log("288", selectedRowData?.status)
    setstatusupdate(selectedRowData?.status);
    // const company_id = localStorage.getItem("company_id");
    // console.log("");
    axios
      .get(`all-jobs`, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      })
      .then((response) => {
        // console.log("alljobsdata", response);
        setJobOrderOptions(response?.data?.message);
        // setJoborderIds(response?.data?.message)
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  }, []);

  useEffect(() => {
    setstatusupdate(selectedRowData?.status);
  }, [selectedRowData?.status]);

  // console.log(candidatedrawerflag,"candidatedrawerflagggggggggggggggggggggggggggggggg")

  const handleTabClick = () => {
    onClose();
    if (setactivitydisable) setactivitydisable(!activitydisable);
    setnewvalue(0);
  };

  // useEffect(() => {
  //   axios
  //     .get(`/all-ques-ans/jobId/${id}`, {
  //       headers: {
  //         authorization: (Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref")),
  //       },
  //     })
  //     .then((response) => {
  //       console.log("ressssss", response.data.message);
  //       setQuestionData(response.data.message);
  //       console.log("questionData", questionData.length);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching questions for job:", error);
  //     });
  // }, []);

  const handleQuesPopupClose = () => {
    const a = quesAnsArray.map((question) => {
      return {
        question_types: question.question_types,
        question_id: question.question_id,
        ques_ans: question.ques_ans,
        answer_text: "",
        // answer_id:null,
        error: null,
      };
    });
    setQuesAnsArray(a);
    setQuesOpenPopup(false);
  };

  // useEffect(() => {
  //   const filterrequired = jobOrderOptions?.filter(
  //     (ele) => ele?.joborder_id == inputValueJobOrder?.split(",")[1]
  //   )[0]?.questionnaire_required;
  //   setquestionrequiredflag(filterrequired);
  // }, [inputValueJobOrder]);

  // console.log(
  //   "*************************************************ddd",
  //   candidatedetails
  // );

  // console.log("himanshuuuuuu",Shortlisted)

  const handleViewResumeClick = () => {
    setValue(0);
    if (resumeRef.current) {
      resumeRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const copyToClipboard = async () => {
    const x = process.env.REACT_APP_FRONTEND_URL;
    try {
      await navigator.clipboard.writeText(
        `${x}/Manage-Candidates/${selectedRowData?.id}`
      );
      setSnackbarMessage("Copied");
      setAlertSeverity("success");
      setSnackbarOpen(true);

      // Close the Snackbar after a delay (e.g., 3 seconds)
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 1500); // Adjust the delay as needed
    } catch (err) {
      setSnackbarMessage("Failed to copy the link.");
      setAlertSeverity("error");
      setSnackbarOpen(true);
    }
  };

  let tabIndex = -1;
  let customTabIndex = -1;

  return (
    <Drawer anchor="right" open={isOpen} onClose={() => onClose}>
      <Box
        tabIndex={0}
        role="button"
        sx={{ width: { xs: 400, sm: 500, md: 750, lg: 850 } }}
      >
        <Box>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: { xs: 1, sm: 2, md: 2, lg: 2 },
            }}
          >
            <Typography variant="h5" component="div">
              Profile
            </Typography>
            <CloseIcon onClick={handleTabClick} sx={{ cursor: "pointer" }} />
          </Typography>
          <Divider />
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              p: { xs: 1, sm: 2, md: 2, lg: 2 },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "start", gap: 3 }}>
              <Typography variant="body1" width={"150px"}>
                {candidatedetails.profile_path ? (
                  <img
                    src={candidatedetails.profile_path}
                    style={{
                      borderRadius: "16px",
                      width: "150px",
                      height: "150px",
                    }}
                    alt="avatar"
                  />
                ) : (
                  <Avatar
                    style={{
                      width: 150,
                      height: 150,
                      borderRadius: "16px",
                    }}
                  />
                )}
              </Typography>
              <Typography variant="body1" width={"60%"}>
                <Typography variant="h4" sx={{ wordWrap: "break-word" }}>
                  {candidatedetails.name ? candidatedetails.name : "NA"}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ wordWrap: "break-word" }}
                  gutterBottom
                >
                  {candidatedetails.current_employer
                    ? candidatedetails.current_employer
                    : "NA"}
                </Typography>
                <Typography
                  variant="body1"
                  color={"text.secondary"}
                  className="fw-500"
                  gutterBottom
                  sx={{ wordWrap: "break-word" }}
                >
                  {candidatedetails.current_loc
                    ? candidatedetails?.current_loc[0] &&
                    candidatedetails.current_loc[0]?.label
                    : "NA"}
                </Typography>
                <Typography
                  variant="body1"
                  color={"text.secondary"}
                  className="fw-500"
                  gutterBottom
                >
                  Created By:{" "}
                  <span style={{ color: "black" }}>
                    {selectedRowData?.entered_by}
                  </span>
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    display: "flex",
                    flexWrap: candidatedetails?.cover_letter && candidatedetails.cover_letter.trim() !== "" ? "wrap" : "nowrap",
                    alignItems: "center",
                    gap: 2,
                    my: 2,
                    textWrap: "nowrap",
                  }}
                >
                  <Typography variant="body1" color={"text.primary"}>
                    <a
                      href={"mailto:" + candidatedetails.email}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <Button
                        size="small"
                        className="fw-700"
                        variant="text"
                        startIcon={<EmailOutlinedIcon />}
                      >
                        Email
                      </Button>
                    </a>
                  </Typography>
                  {candidatedetails?.cover_letter !== null &&
                    candidatedetails?.cover_letter?.trim() !== "" && (
                      <Typography variant="body1" color={"text.primary"}>
                        <a href="#coverletterscrol">
                          <Button
                            size="small"
                            className="fw-700"
                            variant="text"
                            startIcon={<InsertDriveFileOutlinedIcon />}
                          >
                            Cover Letter
                          </Button>
                        </a>
                      </Typography>
                    )}

                  <Typography variant="body1" color={"text.primary"}>
                    <a href="#resumescrol">
                      <Button
                        id="viewResumeButton"
                        size="small"
                        className="fw-700"
                        variant="text"
                        startIcon={<InsertDriveFileOutlinedIcon />}
                        onClick={handleViewResumeClick}
                      >
                        View Resume
                      </Button>
                    </a>
                  </Typography>
                  <Typography variant="body1" color={"text.primary"}>
                    <a
                      href={
                        candidatedetails.linkedIn
                          ? `${candidatedetails.linkedIn.startsWith("http")
                            ? candidatedetails.linkedIn
                            : `https://${candidatedetails.linkedIn}`
                          }`
                          : null
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      <Button
                        size="small"
                        className="fw-700"
                        variant="text"
                        startIcon={<LinkOutlinedIcon />}
                      >
                        Linkedin
                      </Button>
                    </a>
                  </Typography>
                  <Typography variant="body1" color={"text.primary"}>
                    <a>
                      <Button
                        size="small"
                        className="fw-700"
                        variant="text"
                        onClick={copyToClipboard}
                        startIcon={<ShareIcon />}
                      >
                        Share Profile
                      </Button>
                    </a>
                    <Button
                      size="small"
                      className="fw-700"
                      variant="text"
                      onClick={() => {
                        const x = process.env.REACT_APP_FRONTEND_URL;
                        const url = `${x}/Manage-Candidates/${selectedRowData?.id}`;
                        window.open(url, '_blank');
                      }}
                      startIcon={<LaunchOutlinedIcon />}
                    >
                      Open in new tab
                    </Button>
                    <Snackbar
                      open={snackbarOpen}
                      autoHideDuration={4000}
                      onClose={handleSnackbarClose}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                      <Alert
                        onClose={handleSnackbarClose}
                        severity={alertSeverity}
                        variant="filled"
                      >
                        {snackbarMessage}
                      </Alert>
                    </Snackbar>
                  </Typography>
                </Typography>
              </Typography>
              {candidatedrawerflag ? (
                <>

                  {AddCandidatePermission?.add === 1 && (
                    <Button
                      variant="contained"
                      disabled={
                        Jobstatus === "Closed" ||
                        Jobstatus === "Cancelled" ||
                        Jobstatus === "Full"
                      }
                      onClick={handlequestionairequired}
                    >
                      {Shortlisted ? " Apply " : " Add to Job"}
                    </Button>
                  )}
                </>
              ) : issearchnavbar ? (
                <Box>
                  {AddCandidatePermission?.add === 1 && (
                    <Button
                      variant="contained"
                      disabled={
                        Jobstatus === "Closed" ||
                        Jobstatus === "Cancelled" ||
                        Jobstatus === "Full"
                      }
                      onClick={() => {
                        setAddtoJob(true);
                      }}
                    >
                      {" "}
                      Add to Job
                    </Button>
                  )}
                  <Modal
                    open={addtoJob}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h5"
                        className="fw-500"
                        p={2}
                      >
                        Add to Job
                      </Typography>
                      <Divider />
                      <Typography id="modal-modal-description" sx={{ p: 2 }}>
                        <Typography variant="body2" className="fw-400">
                          Select Job
                        </Typography>
                        <Autocomplete
                          value={jobOrder}
                          onChange={(event, newValue) => {
                            setJobOrder(newValue);
                          }}
                          inputValue={inputValueJobOrder}
                          onInputChange={(event, newInputValue) => {
                            const joborderId = newInputValue
                              .split(",")
                              .pop()
                              .trim();
                            setJoborderIds(joborderId);
                            setInputValueJobOrder(newInputValue);
                          }}
                          id="controllable-states-demo"
                          options={jobOrderOptions.map(
                            (option) => `${option.title}, ${option.joborder_id}`
                          )}
                          sx={{ mt: 2 }}
                          renderInput={(params) => (
                            <TextField {...params} label="Job" />
                          )}
                        />
                        <Box
                          sx={{ mt: 2 }}
                          display={"flex"}
                          alignItems={"center"}
                          gap={1.5}
                          flexWrap={"wrap"}
                        ></Box>
                      </Typography>
                      <Divider />
                      <Typography
                        id="modal-modal-footer"
                        variant="body2"
                        sx={{
                          p: 1.8,
                          display: "flex",
                          gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="text"
                          size="small"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={handleAddCandidates}
                        >
                          Add
                        </Button>
                      </Typography>
                    </Box>
                  </Modal>
                </Box>
              ) : (
                <Box>
                  {verifiedstatus !== 0 ? (
                    <Box>
                      {AddCandidatePermission?.edit === 1 && (
                        <>
                          <ButtonGroup
                            variant="contained"
                            ref={anchorRef}
                            aria-label="split button"
                            disabled={
                              Jobstatus === "Closed" ||
                              Jobstatus === "Cancelled" ||
                              Jobstatus === "Full"
                            }
                          >
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={handleClick}
                            >
                              Status{" "}
                            </Button>
                            <Button
                              size="small"
                              variant="outlined"
                              aria-controls={
                                opennew ? "split-button-menu" : undefined
                              }
                              aria-expanded={opennew ? "true" : undefined}
                              aria-label="select merge strategy"
                              aria-haspopup="menu"
                              onClick={handleToggle}
                            >
                              <ArrowDropDownIcon />
                            </Button>
                          </ButtonGroup>
                          <Popper
                            sx={{
                              zIndex: 1,
                            }}
                            open={opennew}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin:
                                    placement === "bottom"
                                      ? "center top"
                                      : "center bottom",
                                }}
                              >
                                <Paper>
                                  <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                      id="split-button-menu"
                                      autoFocusItem
                                    >
                                      {status.map((option, index) => (
                                        <MenuItem
                                          key={option.status}
                                          value={option.status}
                                          disabled={
                                            statusupdate === option.status ||
                                              statusupdate === "Withdrawn"
                                              ? true
                                              : false
                                          }
                                          // selected={index === option.status}
                                          onClick={(event) =>
                                            handleMenuItemClick(
                                              event,
                                              index,
                                              option.status
                                            )
                                          }
                                        >
                                          {option.status}
                                        </MenuItem>
                                      ))}
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>

                          {/* Comment box Dialog box code here  */}

                          <DialogBox
                            title={title}
                            closeDialog={closeDialog}
                            isDialogOpen={isDialogOpen}
                            comment={comment}
                            setComment={setComment}
                            saveCommentAndStatus={saveCommentAndStatus}
                          />
                        </>
                      )}

                      {/* <Dialog open={isDialogOpen} fullWidth>
                        <DialogTitle>Change Status</DialogTitle>
                        <Divider />
                        <DialogContent>
                          <Typography
                            variant="body1"
                            className="fw-500"
                            gutterBottom
                          >
                            Add Comment
                          </Typography>
                          <TextField
                            fullWidth
                            multiline
                            rows={4}
                            label="Comment"
                            variant="outlined"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                          />
                        </DialogContent>
                        <Divider />
                        <Typography
                          style={{ padding: "16px" }}
                          display={"flex"}
                          justifyContent={"flex-end"}
                        >
                          <Button
                            variant="text"
                            color="primary"
                            onClick={closeDialog}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="text"
                            color="primary"
                            onClick={saveCommentAndStatus}
                          >
                            Save
                          </Button>
                        </Typography>
                      </Dialog> */}

                      {/* Comment Dialog box Ends Here */}
                    </Box>
                  ) : (
                    <Button size="small" variant="contained" color="error">
                      unverified
                    </Button>
                  )}
                </Box>
              )}
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                className="fw-700"
                aria-label="basic tabs example"
              >
                <Tab label="Candidate Info" {...a11yProps(++tabIndex)} />

                {!Shortlisted &&
                  !activitydisable &&
                  window.location.pathname !== "/searchCandidate" && (
                    <Tab label="Activities" {...a11yProps(++tabIndex)} />
                  )}
                <Tab label="Internal Profile" {...a11yProps(++tabIndex)} />
                {!Shortlisted &&
                  responsehide &&
                  window.location.pathname !== "/searchCandidate" && (
                    <Tab label="Response" {...a11yProps(++tabIndex)} />
                  )}
              </Tabs>
            </Box>
            <div ref={resumeRef}>
              <CustomTabPanel value={value} index={++customTabIndex}>
                <CandidateInfo candidatedetails={candidatedetails} />
              </CustomTabPanel>
            </div>
            {!Shortlisted &&
              !activitydisable &&
              window.location.pathname !== "/searchCandidate" && (
                <CustomTabPanel value={value} index={++customTabIndex}>
                  <CandidateActivities
                    handleSnackbarOpen={handleSnackbarOpen}
                    selectedStatus={selectedStatus}
                    setstatusupdate={setstatusupdate}
                    setnewvalue={setnewvalue}
                    setactivitesrefresh={setactivitesrefresh}
                    activitesrefresh={activitesrefresh}
                    refreshagain={refreshagain}
                    setrefreshagain={setrefreshagain}
                    selectedRowData={selectedRowData}
                    candidatedetails={candidatedetails}
                    activitydisable={activitydisable}
                  />
                </CustomTabPanel>
              )}
            <CustomTabPanel value={value} index={++customTabIndex}>
              <CandidateEditableProfile
                candidateId={selectedRowData?.id}
                onClose={onClose}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={++customTabIndex}>
              <Questionnairedata id={id} candidateId={selectedRowData?.id} />
            </CustomTabPanel>
          </FormControl>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={
            snackbarMessage == "Candidate Status Changed Successfully" ||
              snackbarMessage === "Candidate Added Successfully" ||
              snackbarMessage === "Comment Added Successfully"
              ? "success"
              : "error"
          }
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Questionpop
        quesOpenPopup={quesOpenPopup}
        quesAnsArray={quesAnsArray}
        setQuesAnsArray={setQuesAnsArray}
        handleQuesPopupClose={handleQuesPopupClose}
        // Joborder_id={inputValueJobOrder?.split(",")[1]}
        handleQuessummit={handleQuessummit}
        textFields={textFields}
        setTextFields={setTextFields}
        radioValues={radioValues}
        setRadioValues={setRadioValues}
      />
    </Drawer>
  );
};

export default CandidateDetails;
