import React, { useEffect, useState } from 'react';
import {
    Box, FormControl, MenuItem, Select, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow,
    Typography,
    Autocomplete,
    TextField,
    Divider,
    FormHelperText,
    InputLabel
} from '@mui/material';
import axios from 'axios';
import Cookies from "js-cookie";
import MenuPermission from './menuPermission';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import { useFetcher, useLocation } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
const PermissionUI = ({
    role,
    setRole,
    selectedAdmin,
    setSelectedAdmin,
    MenuPermissionerror,
    setMenuPermissionerror
}
) => {
    const [roleList, setRoleList] = useState([]);
    const [authorityList, setAuthorityList] = useState([]);
    const [list, setList] = useState([]);

    //for the edit user disabled the fields temporary for now
    const [isEditingDisabled, setIsEditingDisabled] = useState(false); //for the e dit user
    const location = useLocation();
    // Check if user_id is in the query parameters
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const userId = queryParams.get('user_id');
        if (userId) {
            setIsEditingDisabled(true);
        } else {
            setIsEditingDisabled(false);
        }
    }, [location.search]);


    const fetchRole = async () => {
        try {
            const response = await axios.get("/roles", {
                headers: {
                    authorization:
                        Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
                },
            });
            if (response.data.status === 200) {
                setRoleList(response.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const fetchChildAuthority = async () => {
        try {
            const response = await axios.post("/child-users", { user_role_id: role.id }, {
                headers: {
                    authorization:
                        Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
                },
            });
            if (response.data.status === 200) {
                const list = response.data.message;
                setList(list);
                const options = list?.map(user => user.name)
                setAuthorityList(options);
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchRole();
    }, []);

    useEffect(() => {
        if (role.name) {
            const delay = 400;
            const timer = setTimeout(() => {
                fetchChildAuthority();
            }, delay);

            return () => clearTimeout(timer);
        }
    }, [role]);

    const handleRoleChange = (event) => {
        const selectedRoleId = event.target.value;
        const selectedRole = roleList.find(roleItem => roleItem.id === selectedRoleId);
        setRole(selectedRole);
        setSelectedAdmin({});
        // Validate and set error state if necessary
        if (!selectedRole) {
            setMenuPermissionerror({
                ...MenuPermissionerror,
                roleError: true,
            });
        } else {
            setMenuPermissionerror({
                ...MenuPermissionerror,
                roleError: false,
            });
        }
    };
    const permissions = {
        Employer: [
            { department: 'Company', add: true, edit: true, view: true, delete: true },
            { department: 'Branch', add: true, edit: true, view: true, delete: true },
            { department: 'Job', add: true, edit: true, view: true, delete: true },
            { department: 'Candidate', add: true, edit: true, view: true, delete: true },
            { department: 'User', add: true, edit: true, view: true, delete: true }
        ],
        Admin: [
            { department: 'Company', add: true, edit: true, view: true, delete: true },
            { department: 'Branch', add: true, edit: true, view: true, delete: true },
            { department: 'Job', add: true, edit: true, view: true, delete: true },
            { department: 'Candidate', add: true, edit: true, view: true, delete: true },
            { department: 'User', add: true, edit: true, view: true, delete: true }
        ],
        "TA Manager": [
            { department: 'Company', add: false, edit: false, view: true, delete: false },
            { department: 'Branch', add: false, edit: false, view: true, delete: false },
            { department: 'Job', add: true, edit: true, view: true, delete: true },
            { department: 'Candidate', add: true, edit: true, view: true, delete: true },
            { department: 'User', add: true, edit: true, view: true, delete: true }
        ],
        Recruiter: [
            { department: 'Company', add: false, edit: false, view: true, delete: false },
            { department: 'Branch', add: false, edit: false, view: true, delete: false },
            { department: 'Job', add: true, edit: true, view: true, delete: false },
            { department: 'Candidate', add: true, edit: true, view: true, delete: false },
            { department: 'User', add: false, edit: false, view: false, delete: false }
        ]
    };
    const handleSelectedAuthority = (value) => {
        const entry = list.find(entry => entry.name === value);
        if (entry) {
            setSelectedAdmin(entry);
            setMenuPermissionerror({
                ...MenuPermissionerror,
                adminError: false,
            });
        }
        else {
            setMenuPermissionerror({
                ...MenuPermissionerror,
                adminError: true,
            });
        }
    };
    const selected = selectedAdmin?.name;
    return (
        <Box sx={{ p: 1 }}>
            {/* Role Selection */}
            <Box display="flex" alignItems="center" mb={2}>
                <Typography width="29%" variant='body1' fontWeight={500}>
                    Select Role Type
                </Typography>
                <FormControl
                    fullWidth
                    error={MenuPermissionerror.roleError}
                    sx={{ height: '50px' }}  // Ensure FormControl height aligns
                >
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{ top: 0, left: 0, zIndex: 1 }}  // Adjust label positioning if needed
                    >
                        Select Role
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Role"
                        value={role ? role.id : ''}
                        onChange={handleRoleChange}
                        sx={{ height: '100%', display: 'flex', alignItems: 'center' }}  // Center text in Select
                        disabled={isEditingDisabled}
                    >
                        {roleList.map((roleItem) => (
                            <MenuItem key={roleItem.id} value={roleItem.id} sx={{ fontSize: '0.875rem' }}>
                                {roleItem.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {MenuPermissionerror.roleError && (
                        <FormHelperText sx={{ margin: 0 }}>
                            Please select a role.
                        </FormHelperText>
                    )}
                </FormControl>
            </Box>

            {/* {/ Permission Table /} */}
            {Object.keys(role).length > 0 &&
                <TableContainer sx={{ borderRadius: 2, width: '78%', marginLeft: 'auto', mb: 2 }}>
                    <Table size='small' sx={{ borderCollapse: 'separate' }}>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: 'primary.main', color: 'white' }}>
                                <TableCell align='center' sx={{ borderBottom: 'none', borderRight: '2px solid white', color: "#fff" }}>Department</TableCell>
                                <TableCell align='center' sx={{ borderBottom: 'none', borderRight: '1px solid white', color: "#fff" }}>Add</TableCell>
                                <TableCell align='center' sx={{ borderBottom: 'none', borderRight: '1px solid white', color: "#fff" }}>Edit</TableCell>
                                <TableCell align='center' sx={{ borderBottom: 'none', borderRight: '1px solid white', color: "#fff" }}>View</TableCell>
                                <TableCell align='center' sx={{ borderBottom: 'none', borderRight: '1px solid white', color: "#fff" }}>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {permissions[role?.name]?.map((perm, index) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white',
                                        '&:last-child td, &:last-child th': { border: 0 },
                                    }}
                                >
                                    <TableCell align='center' sx={{ pr: 3, borderRight: '1px solid white' }}>{perm.department}</TableCell>
                                    <TableCell align='center' sx={{ color: perm.add ? 'green' : 'red' }}>{perm.add ? <CheckIcon /> : <CloseIcon />}</TableCell>
                                    <TableCell align='center' sx={{ color: perm.edit ? 'green' : 'red' }}>{perm.edit ? <CheckIcon /> : <CloseIcon />}</TableCell>
                                    <TableCell align='center' sx={{ color: perm.view ? 'green' : 'red' }}>{perm.view ? <CheckIcon /> : <CloseIcon />}</TableCell>
                                    <TableCell align='center' sx={{ color: perm.delete ? 'green' : 'red' }}>{perm.delete ? <CheckIcon /> : <CloseIcon />}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }

            <Divider />

            {/* Radio Group - Assign Under */}
            <Box display="flex" alignItems="center" mt={2} gap={2}>
                <Typography
                    width="25%"
                    variant='body1'
                    fontWeight={500}
                    sx={{ whiteSpace: "nowrap", display: "flex", alignItems: "center" }}
                    style={{
                        marginBottom: MenuPermissionerror.adminError ? '17px' : '0px'
                    }}
                >
                    Assign User Under
                </Typography>
                <Autocomplete
                    freeSolo
                    disableClearable
                    id="autocomplete-user-under"
                    style={{ width: '100%' }}
                    options={authorityList}
                    value={selectedAdmin?.name || ""} // Reflect selectedAdmin's name or empty string if none selected
                    onChange={(e, newValue) => handleSelectedAuthority(newValue)} // Trigger handleSelectedAuthority when an option is selected
                    onInputChange={(e, newInputValue) => {
                        if (newInputValue === "") {
                            setSelectedAdmin({}); // Clear selectedAdmin when input is cleared
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select"
                            error={MenuPermissionerror.adminError}
                            helperText={
                                (!MenuPermissionerror.roleError && MenuPermissionerror.adminError)
                                    ? "Please select assign user under."
                                    : (MenuPermissionerror.adminError && MenuPermissionerror.roleError) ? "Please select role." : ""
                            }
                            style={{ height: '100%' }}
                        />
                    )}
                    fullWidth
                    disabled={!role || isEditingDisabled}
                />
            </Box>

        </Box>
    );
};

export default PermissionUI;