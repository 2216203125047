import React, { useState } from 'react'
import { Alert, CircularProgress, Container, Snackbar } from '@mui/material'
import '../App.css';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import Cookies from "js-cookie";

// const baseURL = process.env.REACT_APP_BASEURL;
const defaultTheme = createTheme();



const Forgetpassword = () => {
  const nav = useNavigate();
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);



  const handleSnackbarOpen = (message) => {
    console.log('Snackbar message:', message);
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };


  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let isValid = true;

    if (!emailRegex.test(email)) {
      setEmailError('Invalid email format.');
      isValid = false;
    } else {
      setEmailError('');
    }

    if (isValid) {
      setLoading(true);
      axios.post(`user/verification/email`, { email: email })
        .then((response) => {
          setLoading(false);
          handleSnackbarOpen("Email Sent Successfully");
          setTimeout(() => {
            nav('/login');;
          }, 1500);

        }).catch((err) => {
          setLoading(false);
          handleSnackbarOpen(err.response.data.error);
        })
    }
  };
  const isAuthenticated = !!(
    Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref")
  );
  return (
    <>
      {
        <div>
          <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="sm">
              <CssBaseline />
              {loading && <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 9999,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                backgroundColor: 'rgba(255, 255, 255, 0.0)', // Optional: Add a semi-transparent overlay
              }}>
                <CircularProgress />
              </div>}
              <Box
                sx={{
                  marginTop: 15,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img src="https://jobeze.com/Logo.svg" className='LoginJobezeLogo' alt="Employer Portal" />
              </Box>
              <Box
                sx={{
                  marginTop: 5,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '20px',
                  borderRadius: '16px',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.20);',
                }}
              >
                <Typography textAlign={'start'} component="h1" sx={{ my: 2 }} variant="h4">
                  Forgot Password
                </Typography>
                <Typography component="h1" variant="body1">
                  Enter your registered email to reset your password
                </Typography>
                <Box component="form" width={'100%'} onSubmit={e => { }} sx={{ mt: 1 }}>
                  <TextField
                    margin="dense"
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    size='small'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    error={!!emailError}
                    helperText={emailError}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'start', gap: 4 }}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, mb: 2, py: 1 }}
                      onClick={handleSubmit}
                    >
                      Send Reset Link
                    </Button>
                    <Button
                      type="submit"
                      variant="text"
                      href='/login'
                      sx={{ mt: 3, mb: 2, py: 1 }}
                    >
                      {isAuthenticated ? "Back to Dashboard" : "Back to Login"}
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
              >
                <Alert
                  onClose={handleSnackbarClose}
                  severity={
                    snackbarMessage === "Email Sent Successfully" ? "success" : "error"
                  }
                  sx={{ width: "100%" }}
                >
                  {snackbarMessage}
                </Alert>
              </Snackbar>
            </Container>
          </ThemeProvider>
        </div>}
    </>
  )
}

export default Forgetpassword