import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DeleteCanidateDrawer = ({
  open,
  underAssignRole,
  handleunderAssignRole,
  usersection,
  basicInfo,
  handleClose,
  handleDelete,
  candidatename,
  acknowledged,
  handleAcknowledgeChange,
  roleList,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          padding: "20px 30px 20px 30px",
          borderRadius: "8px",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          position: "relative",
        }}
      >
        <span
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            borderRadius: "50%",
            backgroundColor: "#EAF4FE",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </span>

      </div>
      <Typography style={{ fontWeight: 500, textAlign: "center", fontSize: "16px", fontStyle: "normal", lineHeight: "24px", marginTop: "8px" }}>
        Are you sure, you want to delete
        {usersection ? (
          <span
            style={{
              fontWeight: 700,
              color: "#3394EF",
              textTransform: "capitalize",
            }}
          >
            {" "}
            {basicInfo?.firstName}{" "}{basicInfo?.lastName}{" ( "}{basicInfo?.role_name}{" )"} {"?"}
          </span>
        ) : (
          <span
            style={{
              fontWeight: 700,
              color: "#3394EF",
              textTransform: "capitalize",
            }}
          >
            {" "}
            {candidatename} {"?"}
          </span>
        )}
      </Typography>
      <DialogContent>
        {usersection ? (
          <DialogContentText>
            <img
              src={"/Userdelete.svg"}
              alt="Delete Icon"
              style={{ display: "block", margin: "0 auto 16px" }}
            />
            <Box style={{ padding: "10px 20px", backgroundColor: "#FF4D4F1A", borderRadius: "8px" }}>
              <Typography
                style={{ fontWeight: 500, fontSize: "16px", color: "#FF4D4F" }}
              >
                If you delete this User
              </Typography>
              <Typography
                varient="body1"
                style={{
                  fontWeight: 400,
                  color: "black",
                  marginTop: "10px",
                  fontSize: "14px",
                  paddingLeft: "4px",
                }}
              >
                • The jobs created by the user will be reassigned.
              </Typography>
              <Typography
                variant="body1"
                style={{
                  fontWeight: 400,
                  color: "black",
                  marginTop: "10px",
                  fontSize: "14px",
                  paddingLeft: "4px",
                }}
              >
                • This Candidates created by the user will be reassigned.
              </Typography>
              <Typography
                variant="body1"
                style={{
                  fontWeight: 400,
                  color: "black",
                  marginTop: "10px",
                  fontSize: "14px",
                  paddingLeft: "4px",
                }}
              >
                • Members assigned under the user will be reassigned.
              </Typography>
            </Box>
          </DialogContentText>
        ) : (
          <DialogContentText>
            <img
              src={"/deletePopUp.svg"}
              alt="Delete Icon"
              style={{ display: "block", margin: "0 auto 16px" }}
            />
            <Box style={{ padding: "10px", backgroundColor: "#FF4D4F1A", borderRadius: "8px", }}>
              <Typography
                style={{ fontWeight: 500, fontSize: "16px", color: "#FF4D4F" }}
              >
                If you delete this candidate
              </Typography>
              <Typography
                varient="body1"
                style={{
                  fontWeight: 400,
                  color: "black",
                  marginTop: "10px",
                  fontSize: "14px",
                  paddingLeft: "4px",
                }}
              >
                • The internal profile created for this candidate will be deleted.
              </Typography>
              <Typography
                variant="body1"
                style={{
                  fontWeight: 400,
                  color: "black",
                  marginTop: "10px",
                  fontSize: "14px",
                  paddingLeft: "4px",
                }}
              >
                • This Candidate will be removed from all associated jobs.
              </Typography>
              <Typography
                variant="body1"
                style={{
                  fontWeight: 400,
                  color: "black",
                  marginTop: "10px",
                  marginBottom: "20px",
                  fontSize: "14px",
                  paddingLeft: "4px",
                }}
              >
                • All activities related to this candidate will be deleted.
              </Typography>
            </Box>
          </DialogContentText>
        )}

        {
          usersection ? (
            <>
              <Typography style={{ fontSize: "14px", lineHeight: "22px" }} mt={1} variant='body1' fontWeight={400}>
                <span style={{ color: "red" }}>*</span> Select a member to transfer authority of all activities associated with
                <span
                  style={{
                    fontWeight: 700,
                    textTransform: "capitalize",
                  }}
                > {basicInfo?.firstName}{" "}{basicInfo?.lastName}{" ( "}{basicInfo?.role_name}{" )"} </span>
              </Typography>
              <Box display="flex" alignItems="center" mb={1} mt={1}>
                <FormControl
                  fullWidth
                  sx={{ height: '50px' }} // Ensure FormControl height aligns
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{ top: 0, left: 0, zIndex: 1 }} // Adjust label positioning if needed
                  >
                    Assign Under
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Assign Under"
                    value={underAssignRole ? underAssignRole.id : ''}
                    onChange={handleunderAssignRole}
                    sx={{ height: '100%', display: 'flex', alignItems: 'center' }} // Center text in Select
                  >
                    {roleList.map((roleItem) => (
                      <MenuItem key={roleItem.id} value={roleItem.id} sx={{ fontSize: '0.875rem' }}>
                        {roleItem.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </>
          ) : <></>
        }

        <FormControlLabel
          control={
            <Checkbox
              checked={acknowledged}
              onChange={handleAcknowledgeChange}
            />
          }
          label={
            <span style={{ fontWeight: 500, fontSize: "16px" }}>
              I acknowledge the implications and wish to proceed.
            </span>
          }
        />
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: "center",
          display: "flex",
          gap: "30px",
          padding: "0 25px",
        }}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
          fullWidth
          style={{ borderRadius: "8px", textTransform: "capitalize" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          variant="contained"
          color="error"
          disabled={(usersection && !(acknowledged && (underAssignRole?.id))) || (!usersection && !acknowledged)}
          fullWidth
          style={{ borderRadius: "8px", textTransform: "capitalize" }}
        >
          Delete
        </Button>

      </DialogActions>
    </Dialog>
  );
};

export default DeleteCanidateDrawer;
