import { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalBlogs, setTotalBlogs] = useState(0);
  const navigate = useNavigate();

  const fetchBlogs = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/blogs/${page}?popular=false`);
      setBlogs(response.data.message.blog_result);
      setTotalBlogs(response.data.message.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, [page]);

  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "Date Created",
      width: 200,
      valueGetter: (params) =>
        new Date(params.row.created_at).toLocaleDateString(),
    },
    {
      field: "views",
      headerName: "Views",
      width: 100,
    },
  ];

  return (
    <Box className="pageWrapper">
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button variant="contained" onClick={() => navigate("/blog/create")}>
          Create New Blog
        </Button>
      </Box>

      <div
        style={{ height: "fit-content", width: "100%", position: "relative" }}
      >
        {loading ? (
          <CircularProgress
            sx={{
              margin: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        ) : blogs.length === 0 ? (
          <Typography variant="h6" sx={{ textAlign: "center", marginTop: 3 }}>
            No blogs available
          </Typography>
        ) : (
          <DataGrid
            rows={blogs.map((blog) => ({ ...blog, id: blog.blog_id }))}
            columns={columns}
            onRowClick={({ row }) => navigate(`/blog/details/${row.blog_id}`)}
            rowCount={totalBlogs}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage + 1)}
            pagination
            disableSelectionOnClick
            sx={{ opacity: loading ? 0.5 : 1 }}
          />
        )}
      </div>
    </Box>
  );
};

export default BlogList;
