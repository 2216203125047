import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

const BranchSelection = ({
  branch,
  setBranch,
  selectedBranches,
  handleBranchChange,
  branchRadioValue,
  setBranchRadioValue,
  handleBranchRadioChange,
  handleDeleteBranch,
  branchSelectError,
  branchMessage,
  handleBranchSelectionError,
  selectedAdmin,
}) => {
  const [loading, setLoading] = useState(false);

  const getBranches = async (companyId) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `list-of-branches/${companyId}`,
        { under_assigned_user_id: selectedAdmin },
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      );
      const branchData = response.data.message.branchListResponse;
      setBranch(branchData);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const isBranchSelected = (branchId, index) => {
    return selectedBranches[index].branches.some(
      (branch) => Number(branch.id) === Number(branchId)
    );
  };

  return (
    <>
      {selectedBranches.filter((branch) => branch.is_branch_managed === 1)
        .length > 0
        ? selectedBranches.map(
            (selectedbranch, index) =>
              selectedBranches[index].is_branch_managed === 1 && (
                <Box key={index} pb={1}>
                  <Box display={"flex"} pb={1}>
                    <Typography
                      width={"30%"}
                      variant="body1"
                      sx={{ textTransform: "capitalize", paddingTop: "9px" }}
                      fontWeight={500}
                    >
                      {selectedbranch.company_name}
                    </Typography>
                    <Box sx={{ display: "flex", width: "100%" }}>
                      <FormControl sx={{ width: "50%" }}>
                        <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          value={
                            selectedbranch.branches.includes(-100)
                              ? "all"
                              : "select"
                          }
                          onChange={(e) =>
                            handleBranchRadioChange(
                              e,
                              index,
                              selectedbranch.branches
                            )
                          }
                          sx={{ justifyContent: "end" }}
                        >
                          <FormControlLabel
                            value="all"
                            control={<Radio />}
                            label="All"
                            sx={{ color: "gray" }}
                          />
                          <FormControlLabel
                            value="select"
                            control={<Radio />}
                            label="Select"
                            sx={{ color: "gray" }}
                          />
                        </RadioGroup>
                      </FormControl>
                      {!selectedbranch.branches.includes(-100) && (
                        <Box sx={{ width: "50%" }}>
                          <FormControl
                            fullWidth
                            error={branchSelectError[index]}
                          >
                            <Select
                              value={selectedBranches[index].branches}
                              renderValue={(selected) => (
                                <Typography>
                                  Selected{" "}
                                  {selected?.length > 0
                                    ? `(${selected?.length})`
                                    : ""}
                                </Typography>
                              )}
                              multiple
                              size="small"
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              input={<OutlinedInput />}
                              onBlur={() => handleBranchSelectionError(index)}
                              onChange={(e) => handleBranchChange(e, index)}
                              onOpen={() => {
                                if (
                                  selectedBranches[index].branches.includes(
                                    -100
                                  ) === false
                                ) {
                                  getBranches(selectedbranch.company_id);
                                }
                              }}
                              MenuProps={{
                                PaperProps: { style: { maxHeight: 250 } },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "#0096FF",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "#0096FF",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#0096FF",
                                  },
                                  "&.Mui-disabled fieldset": {
                                    textAlign: "center",
                                  },
                                },
                              }}
                            >
                              {loading ? (
                                <MenuItem alignItems="center" disabled>
                                  Loading...
                                </MenuItem>
                              ) : (
                                branch.map((item) => (
                                  <MenuItem
                                    key={item.id}
                                    sx={{ textTransform: "capitalize" }}
                                    value={item}
                                    disabled={isBranchSelected(item.id, index)}
                                  >
                                    {item.branch_name}
                                  </MenuItem>
                                ))
                              )}
                            </Select>
                            {branchSelectError[index] && (
                              <FormHelperText>
                                Please select at least one branch.
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  {selectedbranch.branches[0] !== -100 &&
                    selectedbranch.branches.length > 0 && (
                      <Box py={1.5}>
                        <Box
                          border={1}
                          p={1.5}
                          borderRadius={1.5}
                          borderColor={"gray"}
                          width={"58%"}
                          marginLeft={"auto"}
                        >
                          <Box
                            display="flex"
                            flexWrap="wrap"
                            justifyContent={"start"}
                            alignContent={"start"}
                            alignItems={"start"}
                            gap={1}
                          >
                            {selectedbranch.branches !== -100 &&
                              selectedbranch.branches.map((Cname, idx) => (
                                <Chip
                                  key={idx}
                                  label={Cname.branch_name}
                                  color="primary"
                                  onDelete={() =>
                                    handleDeleteBranch(index, idx)
                                  }
                                  sx={{
                                    backgroundColor: "rgba(130, 130, 130, 1)",
                                    borderRadius: 2,
                                  }}
                                />
                              ))}
                          </Box>
                        </Box>
                      </Box>
                    )}
                </Box>
              )
          )
        : (selectedBranches.filter((branch) => branch.is_branch_managed === 0)
            .length > 0 ||
            branchMessage) && (
            <Typography
              variant="h6"
              color={"gray"}
              textAlign={"center"}
              fontWeight={500}
            >
              {branchMessage
                ? branchMessage
                : `The selected ${
                    selectedBranches.filter(
                      (branch) => branch.is_branch_managed === 0
                    ).length === 1
                      ? "company"
                      : "companies"
                  } have no branches`}
            </Typography>
          )}
    </>
  );
};
export default BranchSelection;
