import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Container,
  IconButton,
  CircularProgress,
  Typography,
  duration,
} from "@mui/material";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
// import { BarChart } from "@mui/x-charts";
import Header from "../../components/header";
import CustomChips from "./components/customChips";
import DashboardTiles from "./components/dashboardTiles";
import Activities from "./components/activities";
import GraphDatagrid from "./components/GraphDatagrid";
import { PermissionsStatusManage } from "../../constants/defaultValue";
import { permissionStatus } from "../../redux/modules/userSlice";
import { Column } from "@ant-design/plots";
import CandidateDashboard from "./candidateDashboard";

const Customdashboard = () => {
  const dispatch = useDispatch();
  const isEmployerManage = useSelector((state) => state.user.isEmployer);
  const role = useSelector((state) => state.user.role);
  // if (isEmployerManage === true) {
  //   dispatch(permissionStatus(PermissionsStatusManage));
  // }
  const [filterType, setFilterType] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const isEmployer = useSelector((state) => state.user.isEmployer);

  const [selectedChip, setSelectedChip] = useState(searchParams.get("data"));
  const [breadcrumb, setBreadcrumb] = useState([""]);
  const [chartData, setChartData] = useState([]);
  const [jobType, setJobType] = useState("Internal"); // job type for internal public closed cancelled on click status
  const [jobname, setJobname] = useState(); // job name for internal public closed cancelled on click status
  const [JobDuration, setJobDuration] = useState("");
  const [Jobid, setJobid] = useState();
  const [barchartcolor, setBarchartcolor] = useState();
  const [showGraphDatagrid, setShowGraphDatagrid] = useState(false);
  const [Payloadforchart2, setPayloadforchart2] = useState({});
  // const [chartNumber, setChartNumber] = useState(1);
  const chartNumber = useRef(1);
  const JobidNotUpdatedAfterClick = useRef(true);
  const [barchartlabel, setBarchartlabel] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activityData, setActivityData] = useState([]);

  //graph datagrids states
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10); // Set to 10 as per requirement
  const dataGridRecruiterId = useRef();
  const [jobData, setJobData] = useState([]);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(true);
  const [datagridCount, setDatagridCount] = useState(2);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [columns, setColumns] = useState([]);

  const handleJobClick = (Job_type, jobname) => {
    fetchTotalCount();
    setJobType(Job_type);
    setJobname(jobname);
    setJobDuration("");
    setSelectedTimeDuration({
      1: "Last Week",
      2: "Last Week",
      3: "Last Week",
      4: "Last Week",
    });
  };

  useEffect(() => {
    if (
      jobType &&
      (jobType.includes("Internal") ||
        jobType.includes("Public") ||
        jobType.includes("Recently Closed") ||
        jobType.includes("Newly Created") ||
        jobType.includes("Recently Cancelled"))
    ) {
      setPayloadforchart2({});
      if (showGraphDatagrid) {
        setDatagridCount(2); //for the datagrid count
        dataGridRecruiterId.current = null;
        handlePageChange({ page: 1 }); //doing page change to 1 reseting page count
      } else {
        if (JobDuration.length === 0 && !showGraphDatagrid) {
          handleChartData();
          fetchTotalCount();
        } else {
          return;
        }
      }
    }
  }, [jobType, jobname, showGraphDatagrid, JobDuration, selectedChip]);

  const chipItems = [];

  if (isEmployer) {
    chipItems.push(
      {
        name: "Jobs",
        icon: <WorkOutlineOutlinedIcon />,
      },
      {
        name: "Candidates",
        icon: <GroupOutlinedIcon />,
      }
      // {
      //   name: "Company",
      //   icon: <HomeWorkOutlinedIcon />,
      // },
      // {
      //   name: "Users",
      //   icon: <PersonOutlineOutlinedIcon />,
      // },
    );
  }

  const handleChipClick = (chipName) => {
    if (chipName === "Candidates" || chipName === "Jobs") {
      setSelectedChip(chipName);
    }
    setSearchParams({ data: chipName });
  };

  const [anchorElDuration, setAnchorElDuration] = useState({});
  const [selectedTimeDuration, setSelectedTimeDuration] = useState({
    1: "Last Week",
    2: "Last Week",
    3: "Last Week",
    4: "Last Week",
  });
  const handleClickDuration = (event, jobType, value) => {
    setAnchorElDuration({
      ...anchorElDuration,
      [jobType]: event.currentTarget,
    });
  };

  const handleCloseDuration = (event, key, value) => {
    setSelectedTimeDuration((prev) => ({
      ...prev,
      [key]: event,
    }));
    setAnchorElDuration((prev) => ({
      ...prev,
      [key]: null,
    }));
    fetchTotalCount(event, value["jobType"]); // Corrected parameters order
    setPayloadforchart2({});
    // Update jobType and jobname based on payload.job_type
    if (value["jobType"] === "Recently Closed") {
      setJobType("Recently Closed");
      setJobname("Closed Jobs");
      setJobDuration(event);
    } else if (value["jobType"] === "Recently Cancelled") {
      setJobType("Recently Cancelled");
      setJobname("Cancelled Jobs");
      setJobDuration(event);
    } else if (value["jobType"] === "Newly Created") {
      setJobType("Newly Created");
      setJobname("New Jobs");
      setJobDuration(event);
    }
    setUpdateTrigger(true);
  };
  useEffect(() => {
    if (updateTrigger && !showGraphDatagrid) {
      handleChartData();
      setUpdateTrigger(false); // Reset trigger after handling chart data
    } else if (updateTrigger && showGraphDatagrid) {
      setDatagridCount(2); //for the datagrid count
      // fetchRecruiterTabularData(1);
      handlePageChange({ page: 1 });
      dataGridRecruiterId.current = null;
      setUpdateTrigger(false); // Reset trigger after handling chart data
    }
  }, [updateTrigger]);

  const fetchTotalCount = async (event, key) => {
    if (selectedChip === "Candidates") {
      return;
    }
    let payload = {
      job_type: key ? key : "",
      time_span: event ? event : "",
    };
    try {
      const response = await axios.post(`dashboard/job-count`, payload, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });

      const respData = response.data.message;

      // Update filterType state
      if (respData?.length == 1) {
        let destructuredFiltrType = [...filterType];
        const filterIndex = destructuredFiltrType.findIndex(
          (item) => item.title === respData[0].title
        );
        if (filterIndex !== -1) {
          destructuredFiltrType[filterIndex] = respData[0];
        }
        setFilterType(destructuredFiltrType);
      } else {
        setFilterType(respData);
      }
    } catch (error) {
      console.error("Error fetching job count:", error);
      setLoading(false);
    }
  };

  const fetchActivities = async (payload) => {
    if (selectedChip === "Candidates") {
      return;
    }
    if (!payload.job_type || !payload.time_span) {
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        `dashboard/recent-activities`,
        payload,
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      );
      setActivityData(response.data.message);
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleChartData = async () => {
    if (selectedChip === "Candidates") {
      return;
    }
    setLoading(true);
    const payload = {
      type: jobname ? jobname : "Open Jobs",
      section: jobType ? jobType : "Internal",
      duration: JobDuration ? JobDuration : "Last Week", // last 30 days for example
    };

    // Fetch data from first API (/job-recruiters)
    await axios
      .post("/job-recruiters", payload, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      })
      .then((response) => {
        const fetchactivitypayload = {
          job_type: payload.section,
          time_span: payload.duration,
        };
        fetchActivities(fetchactivitypayload);
        chartNumber.current = 2;
        // Map data to format needed for chart
        const chartDataFormatted = response.data.message;
        setBreadcrumb([
          payload.type ? payload.type : "Open Jobs",
          payload.section ? payload.section : "Internal",
          "Recruiters",
        ]);
        setChartData(chartDataFormatted);
        setBarchartcolor("#006CB7");
        JobidNotUpdatedAfterClick.current = true;
        setBarchartlabel(["Recruiters", "Jobs", "Job Count"]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching job recruiters data:", error);
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   fetchTotalCount();
  // }, []);

  const handleAxisClick = async (data) => {
    if (selectedChip === "Candidates") {
      return;
    }
    setLoading(true);
    if (chartNumber.current == 1) {
      setLoading(false);
      return;
    }
    let CurrentJobids = null;
    if (JobidNotUpdatedAfterClick.current === false) {
      setJobid(data.axisValue);
      CurrentJobids = data.axisValue;
    }
    let currentAxisId = null;
    let ChartPayload = {};
    let ChartThreePayload = {};
    if (
      JobidNotUpdatedAfterClick.current === true ||
      chartNumber.current == 2
    ) {
      currentAxisId = data.axisValue;
      ChartPayload = {
        type: jobname ? jobname : "Open Jobs",
        section: jobType ? jobType : "Internal", // Use job_id from clicked job
        duration: JobDuration ? JobDuration : "Last Week",
        recruiter_id: currentAxisId, // Use job_id from clicked job
      };
      setPayloadforchart2((prev) => ({ ...prev, ...ChartPayload }));
      if (currentAxisId) {
        if (chartNumber.current === 1 || chartNumber.current === 3) {
          return;
        }
        await axios
          .post("/candidates-job", ChartPayload, {
            headers: {
              authorization:
                Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
            },
          })
          .then((response) => {
            const fetchactivitypayload = {
              job_type: ChartPayload.section,
              time_span: ChartPayload.duration,
              recruiter_id: ChartPayload.recruiter_id,
            };
            fetchActivities(fetchactivitypayload);
            const chartDataUpdated = response.data.message;
            setChartData(chartDataUpdated);
            setBreadcrumb([
              ChartPayload.type ? ChartPayload.type : "Open Jobs",
              ChartPayload.section,
              "Recruiters",
              "Job Id",
            ]);
            setBarchartcolor("#3F95EA");
            JobidNotUpdatedAfterClick.current = false;
            chartNumber.current = 3;
            setBarchartlabel([
              "Job Name",
              "No. of Candidates",
              "Candidate Count",
            ]);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching candidates job data:", error);
            setLoading(false);
          });
      } else {
        console.error(`Job with index not found in chartData`);
        setLoading(false);
      }
    } else if (chartNumber.current === 3) {
      ChartThreePayload = {
        type: jobname ? jobname : "Open Jobs",
        section: jobType ? jobType : "Internal",
        duration: JobDuration ? JobDuration : "Last Week",
        recruiter_id: Payloadforchart2.recruiter_id, // Use job_id from clicked job
        job_id: CurrentJobids ? CurrentJobids : Jobid, // Use job_id from clicked job of previous chart
      };
      if (CurrentJobids) {
        if (chartNumber.current === 1 || chartNumber.current === 2) {
          setLoading(false);
          return;
        }
        await axios
          .post("/candidates-status-count", ChartThreePayload, {
            headers: {
              authorization:
                Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
            },
          })
          .then((response) => {
            const fetchactivitypayload = {
              job_type: ChartThreePayload.section,
              time_span: ChartThreePayload.duration,
              recruiter_id: ChartThreePayload.recruiter_id,
              job_id: ChartThreePayload.job_id,
            };
            fetchActivities(fetchactivitypayload);
            const chartDataUpdated = response.data.message;
            setChartData(chartDataUpdated);
            setBreadcrumb([
              ChartThreePayload.type ? ChartThreePayload.type : "Open Jobs",
              ChartThreePayload.section,
              "Recruiters",
              "Job Id",
              "Candidate Status",
            ]);
            setBarchartcolor("#457EAB");
            // currentAxisId = null;
            CurrentJobids = null;
            JobidNotUpdatedAfterClick.current = true;
            chartNumber.current = 1;
            setBarchartlabel([
              "Candidate Status",
              "No. of Candidates",
              "Candidate Status",
            ]); //labels of the chart  first one is x-axis and second one is y-axis4
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching candidates status count:", error);
            setLoading(false);
          });
      } else {
        console.log("Error in the not matching ");
        setLoading(false);
      }
    }
  };

  const fetchRecruiterTabularData = async (page) => {
    if (selectedChip === "Candidates") {
      return;
    }
    setLoading(true);

    const payload = {
      type: jobname ? jobname : "Open Jobs",
      section: jobType ? jobType : "Internal",
      duration: JobDuration ? JobDuration : "Last Week",
    };

    try {
      const response = await axios.post(
        `/recruiter-tabular-data/${page}`,
        payload,
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      );
      const fetchactivitypayload = {
        job_type: payload.section,
        time_span: payload.duration,
        // recruiter_id: "",
        // job_id: "",
      };
      fetchActivities(fetchactivitypayload); // Call fetchActivities here
      const data = response.data.message || [];
      setData(data);
      const predefinedColumns = [
        { field: 'id', headerName: 'Id', width: 200 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'job_count', headerName: 'Job Count', width: 150 },
        { field: 'candidate_count', headerName: 'Candidate Count', width: 200 }
      ];
      setColumns(predefinedColumns);
      setDisableNext(data?.length < pageSize);
      setDisablePrev(page === 1);
      setBreadcrumb([payload.type, payload.section, "Recruiters"]);
      setDatagridCount(2);
    } catch (error) {
      console.error("Error fetching job recruiters data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const fetchJobTabularData = async (recruiterId, page) => {
    if (selectedChip === "Candidates") {
      return;
    }
    setLoading(true);
    try {
      const payload = {
        type: jobname ? jobname : "Open Jobs",
        section: jobType ? jobType : "Internal",
        duration: JobDuration ? JobDuration : "Last Week",
        recruiter_id: recruiterId,
      };
      const response = await axios.post(`/job-tabular-data/${page}`, payload, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });
      const fetchactivitypayload = {
        job_type: payload.section,
        time_span: payload.duration,
        recruiter_id: payload.recruiter_id,
        // job_id: "",
      };
      fetchActivities(fetchactivitypayload);
      const data = response.data.message || [];
      setJobData(data);
      const column = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'Applied By Candidate', headerName: 'Applied By Candidate', width: 200 },
        { field: 'Applied By Employer', headerName: 'Applied By Employer', width: 200 }
      ];
      setColumns(column);
      setDisableNext(data?.length < pageSize);
      setDisablePrev(page === 1);
      setBreadcrumb([payload.type, payload.section, "Recruiters", "Job Id"]);
      setPayloadforchart2(payload);
      setDatagridCount(3);
    } catch (error) {
      console.error("Error fetching job data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchStatusTabularData = async (jobId, page) => {
    if (selectedChip === "Candidates") {
      return;
    }
    setLoading(true);
    if (datagridCount === 2 || datagridCount === 1) {
      setLoading(false);
      return;
    }
    if (datagridCount === 3) {
      try {
        const payload = {
          type: jobname ? jobname : "Open Jobs",
          section: jobType ? jobType : "Internal",
          duration: JobDuration ? JobDuration : "Last Week",
          recruiter_id: dataGridRecruiterId.current,
          job_id: jobId,
        };
        const response = await axios.post(
          `/status-tabular-data/${page}`,
          payload,
          {
            headers: {
              authorization:
                Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
            },
          }
        );
        const fetchactivitypayload = {
          job_type: payload.section,
          time_span: payload.duration,
          recruiter_id: payload.recruiter_id,
          job_id: payload.job_id,
        };
        fetchActivities(fetchactivitypayload);
        const data = response.data.message || [];
        setJobData(data);

        const column = [
          { field: 'id', headerName: 'ID', width: 100 },
          { field: 'status', headerName: 'Status', width: 350 },
          { field: 'count', headerName: 'Count', width: 200 }
        ];

        setColumns(column);
        setDisableNext(data?.length < pageSize);
        setDisablePrev(page === 1);
        setBreadcrumb([
          payload.type,
          payload.section,
          "Recruiters",
          "Job Id",
          "Candidate Status",
        ]);
        setDatagridCount(2);
      } catch (error) {
        console.error("Error fetching status data:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRowClick = async (params) => {
    dataGridRecruiterId.current = params.row.id;
    setPage(1);
    fetchJobTabularData(params.row.id, 1);
  };

  const handleJobRowClick = async (params) => {
    await fetchStatusTabularData(params.row.id, 1);
    setPage(1); // Reset the page to 1 for the new data fetch
  };

  const handlePageChange = (params) => {
    setPage(params.page); // Update page directly
    if (dataGridRecruiterId.current) {
      fetchJobTabularData(dataGridRecruiterId.current, params.page);
    } else {
      fetchRecruiterTabularData(params.page);
    }
  };
  const handleBreadcrumbClick = async (index) => {
    const slicedBreadcrumb = breadcrumb.slice(0, index + 1);
    setBreadcrumb(slicedBreadcrumb);

    if (slicedBreadcrumb?.length === 3) {
      if (showGraphDatagrid) {
        setDatagridCount(2);
        dataGridRecruiterId.current = null;
        // fetchRecruiterTabularData(1);
        handlePageChange({ page: 1 });
      } else {
        setBarchartcolor("#006CB7");
        handleChartData();
        setPayloadforchart2({});
      }
    } else if (slicedBreadcrumb?.length === 4) {
      if (showGraphDatagrid) {
        setDatagridCount(2);
        setJobData([]);
        // fetchJobTabularData(Payloadforchart2.recruiter_id, 1);
        handlePageChange({ page: 1 });
      } else {
        setLoading(false);
        setBarchartcolor("#3F95EA");
        chartNumber.current = 2;
        handleAxisClick({ axisValue: Payloadforchart2?.recruiter_id });
      }
    }
  };

  const handleListClick = () => {
    setShowGraphDatagrid(true); //for show the datagrid and graph on true or false onclick of the icon
  };

  //calculating the y-axis for the barchart
  const maxYaxis = (data) => {
    const yaxisCount = {
      max: 5,
      tickcount: 5,
    };

    const maxCount = Math.max(...data.map((item) => item.count));

    if (maxCount + 1 <= 5) {
      yaxisCount.max = maxCount + 1;
      yaxisCount.tickcount = maxCount + 1;
    } else {
      const tempMax = Math.floor(maxCount / 5);
      yaxisCount.max = maxCount + tempMax;
    }

    return yaxisCount;
  };

  const yaxisCount = maxYaxis(chartData);
  //calculation the ratio for the chart size
  const Handleratio = () => {
    const ratio = 10 / chartData?.length;
    if (ratio >= 1) {
      return null;
    } else {
      return ratio;
    }
  };

  //adding the id and name in the chart data
  const updatedChartData = chartData.map((item) => {
    const formattedName = item.name
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first character and first character after each space

    return {
      ...item,
      nameWithId: `${formattedName} (${item.id})`,
    };
  });

  const getChartHeight = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1600) {
      return 474; // Height for extra-large screens (1600px+)
    } else if (screenWidth >= 1200) {
      return 260; // Height for large screens (1200px+)
    } else {
      return 240; // Default height for smaller screens
    }
  };

  const [dimensions, setDimensions] = useState({
    chartHeight: getChartHeight(),
    gap: 3,
  });


  const handleResize = () => {
    const newChartHeight = getChartHeight();
    const newGap = window.innerWidth >= 1600 ? 2 :
      window.innerWidth >= 1200 ? 2 :
        window.innerWidth >= 800 ? 1 :
          0.5;

    setDimensions({
      chartHeight: newChartHeight,
      gap: newGap,
    });
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //for chart now don't use directly chartdata use updatedchartdata for differenciate between same jobs name
  //creating chart config file
  const ChartDataDisplay = () => {
    const config = {
      smooth: true,
      data: updatedChartData,
      xField: "nameWithId",
      yField: "count",
      height: dimensions.chartHeight,
      style: {
        fill: barchartcolor,
        radiusTopLeft: 10,
        radiusTopRight: 10,
      },
      scrollbar: {
        x: {
          ratio: Handleratio(),
        },
      },
      scale: {
        y: {
          tickCount: yaxisCount.tickcount,
          domainMax: yaxisCount.max,
        },
      },
      animation: false,
      axis: {
        x: {
          labelSpacing: 10,
          labelFontSize: 14,
          labelFontWeight: 700,
          labelAlign: "center",
          labelTransform: "Capitalize",
          labelFormatter: (val) => {
            // Define specific mappings
            const mapping = {
              "Offer Accepted (2000)": "Off. Acc.",
              "Offer Rejected (3000)": "Off. Rej.",
              "Offer Released (2500)": "Off. Rel.",
              "Tech Evaluation (2200)": "Tech. Eval.",
              "Manager Evaluation (2300)": "Man. Eval.",
            };
            // Check if the value has a specific mapping
            if (mapping[val]) {
              return mapping[val];
            }
            // Split the value into parts
            const parts = val.split(" ")[0];
            // Handle cases with more than one part but three or fewer parts
            if (parts.length > 1) {
              return parts;
            }
            // If the value is a single word, return it as-is
            return val;
          },

          title: barchartlabel[0],
          line: {
            style: {
              stroke: "#000", // Color of the x-axis line
              lineWidth: 1, // Width of the x-axis line
            },
          },
          grid: null, // Disable grid lines for x-axis
        },
        y: {
          title: barchartlabel[1],
          labelSpacing: 4,
          labelFontSize: 14,
          labelFontWeight: 700,
          line: {
            style: {
              stroke: "#000", // Color of the y-axis line
              lineWidth: 1, // Width of the y-axis line
            },
          },
          grid: null, // Disable grid lines for y-axis
        },
      },
      //handleaxis functions giving axis value
      onReady: ({ chart }) => {
        chart.on(`interval:click`, (ev) => {
          handleAxisClick({ axisValue: ev.data.data.id });
        });
      },
    };
    return config;
  };

  return (
    <>
      <Box sx={{ backgroundColor: "#E3F2FD", p: 2.5, height: "100%" }}>
        <Box sx={{ flexGrow: 1 }}>
          <CustomChips
            chipItems={chipItems}
            handleClick={handleChipClick}
            selectedChip={selectedChip}
          />
        </Box>
        <Box sx={{ mt: "20px  " }}>
          {selectedChip === "Jobs" && (
            <Box
              gap={dimensions.gap}
              sx={{
                display: "flex",
                // py: 1.3,
                // px: 2,
                // backgroundColor: "white",
                borderRadius: "10px",
                flexGrow: 1,
                mt: 2,
                // flexWrap: "wrap",
              }}
            >
              {Object.entries(filterType).map(([key, value]) => (
                <DashboardTiles
                  key={key}
                  anchorEl={anchorElDuration[key]}
                  setAnchorElDuration={setAnchorElDuration}
                  open={Boolean(anchorElDuration[key])}
                  selectedTime={selectedTimeDuration[key]}
                  handleClick={(event) =>
                    handleClickDuration(event, key, value)
                  }
                  handleClose={(event) =>
                    handleCloseDuration(event, key, value)
                  }
                  jobDetails={value}
                  handleJobClick={handleJobClick}
                  selectedJobType={jobType}
                />
              ))}
            </Box>
          )}
          {/* {selectedChip === "Company" && <p>Companies</p>} */}
          {selectedChip === "Candidates" && (
            <>
              <CandidateDashboard />
            </>
          )}
          {/* {selectedChip === "Users" && <p>Users</p>} */}
        </Box>

        {selectedChip === "Jobs" && (
          <Box sx={{ mt: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  backgroundColor: "white",
                  borderRadius: 2,
                  py: 1,
                  px: 2,
                  flexGrow: 1,
                  width: "70%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Breadcrumbs separator="›">
                    {breadcrumb.map((crumb, index) => (
                      <React.Fragment key={index}>
                        {index === 0 || index === 1 ? (
                          <Typography
                            color="#737791"
                            style={{ fontSize: "1.2rem" }}
                          >
                            {crumb}
                          </Typography>
                        ) : (
                          <Typography
                            onClick={() => handleBreadcrumbClick(index)}
                            style={{
                              fontSize: "18px",
                              fontWeight: index === breadcrumb.length - 1 ? "bold" : "bold",
                              color: index === breadcrumb.length - 1 ? "#3F95EA" : "black",
                              cursor: "pointer",
                            }}
                          >
                            {crumb}
                          </Typography>
                        )}
                      </React.Fragment>
                    ))}
                  </Breadcrumbs>

                  <Box sx={{ display: "flex", gap: 1 }}>
                    <IconButton
                      aria-label="bar"
                      onClick={() => {
                        setShowGraphDatagrid(false);
                        setJobDuration("");
                        setSelectedTimeDuration({
                          1: "Last Week",
                          2: "Last Week",
                          3: "Last Week",
                          4: "Last Week",
                        });
                      }}
                    >
                      <BarChartOutlinedIcon
                        color={showGraphDatagrid ? "default" : "primary"}
                      />
                    </IconButton>
                    <IconButton aria-label="list" onClick={handleListClick}>
                      <FormatListBulletedIcon
                        color={showGraphDatagrid ? "primary" : "dafault"}
                      />
                    </IconButton>
                  </Box>
                </Box>

                <Box className="chart-wrapper">
                  {loading && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // height: 300,
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                  {showGraphDatagrid === false && loading === false && (
                    <Column className="dashboardCharts" {...ChartDataDisplay()} />
                  )}

                  {showGraphDatagrid && loading === false && (
                    <Box>
                      <GraphDatagrid
                        data={data}
                        columns={columns}
                        pageSize={pageSize}
                        jobData={jobData}
                        loading={loading}
                        disablePrev={disablePrev}
                        disableNext={disableNext}
                        page={page}
                        handlePageChange={handlePageChange}
                        handleRowClick={handleRowClick}
                        handleJobRowClick={handleJobRowClick}
                        dataGridRecruiterId={dataGridRecruiterId.current}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  backgroundColor: "white",
                  borderRadius: 2,
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2.5,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    background: "#F9F9F9",
                    p: 2,
                    borderRadius: "8px 8px 0 0",
                  }}
                  textAlign={"start"}

                // padding={1}
                >
                  Recent Activities
                </Typography>
                <Box
                  sx={{
                    height: "calc(100vh - 472px)",
                    position: "relative",
                    overflowY: "scroll",
                    px: 2,
                  }}
                >
                  <Activities activityData={activityData} loading={loading} />
                </Box>
              </Box>
              {/* <Activities activityData={activityData} loading={loading} /> */}
            </Box>
          </Box>
        )}
      </Box >
    </>
  );
};

export default Customdashboard;
