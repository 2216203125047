import { Box, Typography } from "@mui/material";
import React from "react";

const Backdrop = ({ backDropIcon, backDropText }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "50vmin",
        width:'100%'
      }}
    >
      {React.cloneElement(backDropIcon, {
        sx: { fontSize: "30vmin", color: "#ccc" },
      })}
      <Typography sx={{ fontSize: "3vmin", color: "#ccc" }}>
        {backDropText}
      </Typography>
    </Box>
  );
};

export default Backdrop;
