import React, { useState } from "react";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Filter } from "@mui/icons-material";
import { FilterTypes } from "./filterTypes";

const DashboardTiles = ({
  anchorEl,
  open,
  handleClose,
  selectedTime,
  handleClick,
  jobDetails,
  handleJobClick,
  selectedJobType,
  setAnchorElDuration,
  // handleChartData,
}) => {
  const handleJobTileClick = (type, jobname) => {
    handleJobClick(type, jobname);
    // handleChartData();
  };
  const total = jobDetails.Internal + jobDetails.Public;
  const customWidths = {
    'Open Jobs': '30%',     // Custom width for 'Open Jobs'
    'Closed Jobs': '22%',   // Custom width for 'Closed Jobs'
    'New Jobs': '22%',      // Custom width for 'New Jobs'
    'Cancelled Jobs': '22%' // Custom width for 'Cancelled Jobs'
  }
  return (
    <Box
      backgroundColor={"white"}
      sx={{
        padding: "20px",
        gap: "16px",
        borderRadius: "10px",
        flexWrap: "nowrap",
        maxWidth: "100%",
        width: customWidths[jobDetails.title],
      }}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"end"}
        mb={1.5}
        flexWrap={"nowrap"}
        width={"100%"}
      >
        <Typography variant="body1" sx={{ fontWeight: 700, fontSize: "18px" }}>
          {jobDetails.title}
        </Typography>

        {jobDetails.title !== "Open Jobs" && (
          <FilterTypes
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            selectedTime={selectedTime}
            handleClick={handleClick}
            setAnchorElDuration={setAnchorElDuration}
          />
        )}
      </Box>

      <Box display={"flex"} width={"100%"}>
        {jobDetails.title === "Open Jobs" && (
          <Box
            display={"flex"}
            width={"100%"}
            gap={window.screen.width >= 1600 ? 3 : 1}
          >
            <Box
              sx={{
                backgroundColor:
                  selectedJobType === "Internal" ? "#3F95EA" : "white",
                borderRadius: "10px",
                color: selectedJobType === "Internal" ? "white" : "black",
                p: "12px 20px 12px 20px",
                border: "1px solid rgba(0, 108, 183, 0.20)",
                height: "90px",
                cursor: "pointer",
              }}
              width={"50%"}
              onClick={() => handleJobTileClick("Internal", "Open Jobs")}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, fontSize: "24px" }}
              >
                {jobDetails.Internal}
                <span
                  style={{
                    fontSize: "12px",
                    marginLeft: "0.25em",
                    fontWeight: 400,
                  }}
                >
                  / {total}
                </span>
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: 400, fontSize: "16px" }}
              >
                Internal
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor:
                  selectedJobType === "Public" ? "#3F95EA" : "white",
                borderRadius: "10px",
                color: selectedJobType === "Public" ? "white" : "black",
                p: "12px 20px 12px 20px",
                border: "1px solid rgba(0, 108, 183, 0.20)",
                height: "90px",
                cursor: "pointer",
              }}
              // width={window.screen.width >= 1600 ? '15%' : '25%'}
              width={"50%"}
              onClick={() => handleJobTileClick("Public", "Open Jobs")}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, fontSize: "24px" }}
              >
                {jobDetails.Public}
                <span
                  style={{
                    fontSize: "12px",
                    marginLeft: "0.25em",
                    fontWeight: 400,
                  }}
                >
                  / {total}
                </span>
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: 400, fontSize: "16px" }}
              >
                Public
              </Typography>
            </Box>
          </Box>
        )}

        {jobDetails.title === "Closed Jobs" && (
          <Box display={"flex"} gap={1} width={"100%"}>
            <Box
              sx={{
                backgroundColor:
                  selectedJobType === "Recently Closed" ? "#3F95EA" : "white",
                color:
                  selectedJobType === "Recently Closed" ? "white" : "black",
                p: "12px 20px 12px 20px",
                border: "1px solid rgba(0, 108, 183, 0.20)",
                height: "90px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              // width={window.screen.width >= 1600 ? '20%' : '35%'}
              width={"100%"}
              onClick={() =>
                handleJobTileClick("Recently Closed", "Closed Jobs")
              }
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, fontSize: "24px" }}
              >
                {jobDetails.count}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: 400, fontSize: "16px" }}
              >
                Recently Closed
              </Typography>
            </Box>
          </Box>
        )}

        {jobDetails.title === "New Jobs" && (
          <Box display={"flex"} gap={1} width={"100%"}>
            <Box
              sx={{
                color: selectedJobType === "Newly Created" ? "white" : "black",
                backgroundColor:
                  selectedJobType === "Newly Created" ? "#3F95EA" : "white",
                border: "1px solid rgba(0, 108, 183, 0.20)",
                p: "12px 20px 12px 20px",
                height: "90px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              // width={window.screen.width >= 1600 ? '20%' : '35%'}
              width={"100%"}
              onClick={() => handleJobTileClick("Newly Created", "New Jobs")}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, fontSize: "24px" }}
              >
                {jobDetails.count}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: 400, fontSize: "16px" }}
              >
                Newly Created
              </Typography>
            </Box>
          </Box>
        )}

        {jobDetails.title === "Cancelled Jobs" && (
          <Box display={"flex"} gap={1} width={"100%"}>
            <Box
              sx={{
                color:
                  selectedJobType === "Recently Cancelled" ? "white" : "black",
                backgroundColor:
                  selectedJobType === "Recently Cancelled"
                    ? "#3F95EA"
                    : "white",
                p: "12px 20px 12px 20px",
                border: "1px solid rgba(0, 108, 183, 0.20)",
                height: "90px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              // width={window.screen.width >= 1600 ? '20%' : '35%'}
              width={"100%"}
              onClick={() =>
                handleJobTileClick("Recently Cancelled", "Cancelled Jobs")
              }
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, fontSize: "24px" }}
              >
                {jobDetails.count}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: 400, fontSize: "16px" }}
              >
                Recently Cancelled
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DashboardTiles;
