import { Box, CircularProgress } from "@mui/material";
import React from "react";

const Loader = () => {
  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "center",
        height: "50vmin",
        width:'100%'
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Loader;
