import React, { useEffect, useState } from "react";
import Header from "../components/header";
import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { userEdit } from "../redux/modules/userSlice";
import Loader from "../components/Loader";
import Backdrop from "../components/Backdrop";
import dayjs from "dayjs";
import Chip from "@mui/material/Chip";

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: "none",
  padding: theme.spacing(0.5),
}));

const UsersLists = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [usersListRows, setUsersListRows] = useState([]);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [queryOptions, setQueryOptions] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const [paginationModel, setPaginationModel] = useState({
    page: Number(searchParams.get("page")) - 1,
    pageSize: 25,
  });
  const [showLoading, setShowLoading] = useState(true);

  const columns = [
    {
      field: "id",
      headerName: "Id",
      width: 100,
      renderCell: (params) => (
        <a
          href={`/UsersDetails/${params.row.id}?tab=b_info`}
          style={{ textDecoration: "none", color: "black" }}
        >
          <Typography variant="body1">{params.row.id}</Typography>
        </a>
      ),
    },
    {
      field: "full_name",
      headerName: "Full Name",
      width: 200,
      renderCell: (params) => (
        <a
          href={`/UsersDetails/${params.row.id}?tab=b_info`}
          style={{ textDecoration: "none", color: "black" }}
        >
          <Typography
            variant="body1"
            title={params.row.first_name}
            sx={{
              textTransform: "capitalize",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {`${params.row.first_name} ${params.row.last_name}`}
          </Typography>
        </a>
      ),
    },
    {
      field: "role",
      headerName: "Role",
      width: 100,
      renderCell: (params) => (
        <a
          href={`/UsersDetails/${params.row.id}?tab=b_info`}
          style={{ textDecoration: "none", color: "black" }}
        >
          <Typography variant="body1">
            {params.row.role.split(" ")[0]}
          </Typography>
        </a>
      ),
    },
    {
      field: "assigned_under",
      headerName: "Assigned Under",
      width: 200,
      renderCell: (params) => (
        <a
          href={`/UsersDetails/${params.row.id}?tab=b_info`}
          style={{ textDecoration: "none", color: "black" }}
        >
          <Typography variant="body1">{params.row.assigned_under}</Typography>
        </a>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      renderCell: (params) => (
        <a
          href={`/UsersDetails/${params.row.id}?tab=b_info`}
          style={{ textDecoration: "none", color: "black" }}
        >
          <Typography variant="body1">{params.row.email}</Typography>
        </a>
      ),
    },
    {
      field: "companies_associated",
      headerName: "Companies Associated",
      width: 200,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <a
          href={`/UsersDetails/${params.row.id}?tab=b_info`}
          style={{ textDecoration: "none", color: "black" }}
        >
          <div style={{ textAlign: "center", width: "100%" }}>
            {params.row.companies_associated
              ? params.row.companies_associated
              : "NA"}
          </div>
        </a>
      ),
    },
    {
      field: "job_count",
      headerName: "Jobs Created By User",
      width: 200,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <a
          href={`/UsersDetails/${params.row.id}?tab=b_info`}
          style={{ textDecoration: "none", color: "black" }}
        >
          <div style={{ textAlign: "center", width: "100%" }}>
            {params.row.job_count ? params.row.job_count : "NA"}
          </div>
        </a>
      ),
    },
    {
      field: "candidate_count",
      headerName: "Candidates Created By User",
      width: 200,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <a
          href={`/UsersDetails/${params.row.id}?tab=b_info`}
          style={{ textDecoration: "none", color: "black" }}
        >
          <div style={{ textAlign: "center", width: "100%" }}>
            {params.row.candidate_count ? params.row.candidate_count : "NA"}
          </div>
        </a>
      ),
    },
    {
      field: "last_login_date",
      headerName: "Last Login Date",
      width: 200,
      filterable: true,
      sortable: true,
      renderCell: (params) => (
        <a
          href={`/UsersDetails/${params.row.id}?tab=b_info`}
          style={{ textDecoration: "none", color: "black" }}
        >
          <Typography>
            {params.row.last_login_date
              ? dayjs(params.row.last_login_date).format("YYYY-MM-DD")
              : "Not Logged Yet"}
          </Typography>
        </a>
      ),
    },
    {
      field: "user_status",
      headerName: "Status",
      width: 150,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        const chipColor =
          params?.row?.status === "active" ? "success" : "error";
        return (
          <Chip
            label={params?.row?.status === "active" ? "Active" : "InActive"}
            variant="contained"
            clickable
            color={chipColor}
            // style={{
            //   color:"white",
            //   borderColor: params.row.status === "active" ? "green" : "red",
            //   background: params.row.status === "active" ? "green" : "red",
            //   fontWeight:"bolder"
            // }}
          />
        );
      },
    },
  ];

  const HandleRowClick = (params, event) => {
    // console.log(params, "params");
    // console.log(params.row, "params.row");
    const currentUrl = window.location.href;
    sessionStorage.setItem("UserpreviousUrl", currentUrl);
    dispatch(userEdit(params.row.id));
    navigate(`/UsersDetails/${params.row.id}?tab=b_info`);
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setSearchParams({ ...searchParams, search: query, page: 1 }); // Reset to page 1 on search
  };

  const handleSortModelChange = React.useCallback((sortModel) => {
    console.log("sortModel", sortModel);
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);

  const listofUsersApi = async () => {
    let payload = {};
    setShowLoading(true);
    if (queryOptions.sortModel && queryOptions.sortModel.length > 0) {
      payload.sortField = queryOptions?.sortModel[0].field;
      payload.sortBy = queryOptions?.sortModel[0].sort;
    }
    if (queryOptions.pageSize) {
      payload.pageSize = queryOptions.pageSize ? queryOptions.pageSize : 25;
    }
    try {
      const response = await axios.post(
        `list-of-users/${searchParams.get("page")}${
          searchQuery ? "?search=" + searchQuery : ""
        }`,
        payload,
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      );

      setTotalCount(response.data.message.total);
      setUsersListRows(response.data.message.userListResponse);
      dispatch(userEdit(null));
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }
  };

  const handleAddUser = () => {
    navigate("/addUser?tab=basicInfo");
    dispatch(userEdit(null));
  };

  // console.log(paginationModel, "paginationModel");
  const handlePaginationModelChange = (model) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setPaginationModel({ ...paginationModel, ...model });
    setSearchParams({ page: model.page + 1 });
    setQueryOptions({
      ...queryOptions,
      pageSize: model.pageSize,
    });
  };

  useEffect(() => {
    setSearchQuery(searchParams.get("search") || "");
    setPaginationModel({
      page: Number(searchParams.get("page")) - 1 || 0,
      pageSize: queryOptions.pageSize || 25,
    });
  }, [searchParams]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    listofUsersApi();
  }, [searchQuery, queryOptions, paginationModel]);

  return (
    <Box className="pageWrapper">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          id="standard-search"
          type="search"
          variant="standard"
          placeholder="Search"
          autoComplete="off"
          onChange={handleSearch}
          value={searchQuery}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            width: "25%",
          }}
        />
        <Button
          startIcon={<AddIcon />}
          onClick={() => handleAddUser()}
          variant="contained"
        >
          Add User
        </Button>
      </Box>
      {showLoading ? (
        <Loader />
      ) : usersListRows.length === 0 ? (
        <Backdrop
          backDropIcon={<PersonOffOutlinedIcon />}
          backDropText={"No User Found"}
        />
      ) : (
        <Box sx={{ py: 2.5 }}>
          <DataGrid
            rows={usersListRows}
            className="data-grid-row-hover"
            columns={columns}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            pageSizeOptions={[25, 50, 75, 100]}
            rowCount={totalCount}
            autoHeight={true}
            onSortModelChange={handleSortModelChange}
            sortModel={queryOptions.sortModel}
            onRowClick={HandleRowClick}
          />
        </Box>
      )}
    </Box>
  );
};

export default UsersLists;
