import {
  Box,
  Button,
  Divider,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import InsertChartOutlinedTwoToneIcon from "@mui/icons-material/InsertChartOutlinedTwoTone";
import DataUsageOutlinedIcon from "@mui/icons-material/DataUsageOutlined";
import SunburstChart from "./SunburstChart";
import BarChart from "./BarChart";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import TimeSelector from "../Dashboard/components/TimeSelector";
import Activities from "../Dashboard/components/activities";

const OverviewCard = ({ icon, color, count, text, index }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        px: 2.5,
        pt: 1.25,
        pb: 2.5,
        gap: 1.25,
        width: "100%",
        border: "1px solid rgba(0, 108, 183, 0.15)",
        borderRadius: 2.5,
      }}
      key={index}
    >
      <Box
        sx={{
          background: color,
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          height: "50px",
          width: "50px",
        }}
      >
        {icon}
      </Box>
      <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
        {count}
      </Typography>
      <Typography sx={{ fontWeight: "300" }}>{text}</Typography>
    </Box>
  );
};

const CountText = ({
  color,
  highlightCount,
  totalCount,
  highlightedText,
  simpleText,
  lastText,
  itemPlacement,
  countBoxWidth,
}) => {
  return (
    <Box sx={{ width: countBoxWidth }}>
      <Box
        sx={{
          width: "fit-content",
          margin:
            itemPlacement === "right"
              ? "0 0 0 auto"
              : itemPlacement === "center"
                ? "0 auto 0 auto"
                : 0,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1.25 }}>
          <Box
            sx={{
              height: "9px",
              width: "9px",
              borderRadius: "50%",
              backgroundColor: color,
            }}
          ></Box>
          <Box>
            <span style={{ fontSize: "20px" }}>{highlightCount}</span>
            <span style={{ fontSize: "10px", color: "#999" }}>
              {totalCount || totalCount === 0 ? " / " : ""}
              {totalCount}
            </span>
          </Box>
        </Box>
        <Box sx={{ fontSize: "12px", pt: 0.5 }}>
          <span>{highlightedText}</span>
          <span style={{ color: "#999" }}>
            {highlightedText ? ` / ${simpleText}` : simpleText}
          </span>
        </Box>
        <Typography sx={{ fontSize: "12px" }}>{lastText}</Typography>
      </Box>
    </Box>
  );
};

const overviewCardArr = [
  {
    icon: <WorkOutlineOutlinedIcon />,
    color: "#FFA800",
    count: "0",
    text: "Total Jobs",
    key: "total_jobs",
  },
  {
    icon: <PeopleAltOutlinedIcon />,
    color: "#AE64BB",
    count: "0",
    text: "Total Candidates",
    key: "total_candidates",
  },
  {
    icon: <SupervisorAccountOutlinedIcon />,
    color: "#64AE68",
    count: "0",
    text: "Assigned Admins",
    key: "total_admins",
  },
  {
    icon: <SupervisorAccountOutlinedIcon />,
    color: "#F78426",
    count: "0",
    text: "Assigned TA Managers",
    key: "total_ta",
  },
  {
    icon: <SupervisorAccountOutlinedIcon />,
    color: "#44A4F1",
    count: "0",
    text: "Assigned Recruiters",
    key: "total_recruiters",
  },
];

const MyDashboard = () => {
  const [jobCount, setJobCount] = useState({});
  const [candidatesCount, setCandidatesCount] = useState({});
  const [overView, setOverView] = useState(overviewCardArr);
  const [jobsFilterOption, setJobsFilterOption] = useState({
    label: "Last Week",
    value: "7d",
  });
  const [loading, setLoading] = useState({
    overView: false,
    jobs: false,
    candidates: false,
    activities: false,
  });
  const [activities, setActivities] = useState([]);

  const nav = useNavigate();

  const handleTimeFilters = (data) => {
    setJobsFilterOption(data);
  };

  const handleNavigation = (path) => {
    nav(path);
  };

  const fetchOverViewCount = async () => {
    try {
      setLoading((prev) => ({ ...prev, overView: true }));
      const response = await axios.get("my-dashboard/overview-data", {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });

      const data = response.data.message.result;
      const newOverviewArr = [...overView];
      const arr = [];

      for (let i = 0; i < newOverviewArr.length; i++) {
        const overViewItem = Object.keys(data).find(
          (key) => key === newOverviewArr[i].key
        );
        if (overViewItem) {
          arr.push({ ...newOverviewArr[i], count: data[overViewItem] });
        }
      }

      setOverView(arr);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => ({ ...prev, overView: false }));
    }
  };

  const fetchDashboardJobCount = async () => {
    try {
      setLoading((prev) => ({ ...prev, jobs: true }));
      const response = await axios.get("/my-dashboard/job-count", {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
        params: {
          filterPeriod: jobsFilterOption.label,
        },
      });
      const data = response.data.message[0];
      setJobCount({
        activeJobs: {
          existingJobs: data.activeJobs.existingJobs || 0,
          newJobs: data.activeJobs.newJobs || 0,
          totalActiveJobs:
            (data.activeJobs.existingJobs || 0) +
            (data.activeJobs.newJobs || 0),
        },
        inactiveJobs: {
          cancelledJobs: data.inactiveJobs.cancelledJobs || 0,
          closedJobs: data.inactiveJobs.closedJobs || 0,
          totalInactiveJobs:
            (data.inactiveJobs.cancelledJobs || 0) +
            (data.inactiveJobs.closedJobs || 0),
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => ({ ...prev, jobs: false }));
    }
  };

  const fetchDashboardCandidates = async () => {
    try {
      setLoading((prev) => ({ ...prev, candidates: true }));
      const response = await axios.get("/my-dashboard/candidate-count", {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });
      setCandidatesCount(response.data.message[0].result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => ({ ...prev, candidates: false }));
    }
  };

  const fetchDashboardActivities = async () => {
    try {
      setLoading((prev) => ({ ...prev, activities: true }));
      const response = await axios.get("/my-dashboard/recent-activities", {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });
      setActivities(response.data.message.result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => ({ ...prev, activities: false }));
    }
  };

  useEffect(() => {
    fetchDashboardJobCount();
  }, [jobsFilterOption]);

  useEffect(() => {
    fetchOverViewCount();
    fetchDashboardCandidates();
    fetchDashboardActivities();
  }, []);

  return (
    <Box sx={{ background: "#E3F2FD", p: 2.5, minHeight: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2.5,
          height: "100%",
        }}
      >
        <Box sx={{ p: 2.5, background: "#FFF", borderRadius: 2.5 }}>
          <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
            My Overview
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2.5,
              pt: 2.5,
            }}
          >
            {loading.overView ? (
              <Box
                sx={{
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                  height: "162px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              overView.map((card, index) => (
                <OverviewCard
                  key={index}
                  icon={card.icon}
                  color={card.color}
                  count={card.count}
                  text={card.text}
                />
              ))
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2.5,
          }}
        >
          <Box
            sx={{
              background: "#FFF",
              width: "100%",
              borderRadius: 2.5,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "#F9F9F9",
                px: 2.5,
                py: 1.25,
                borderRadius: "10px 10px 0 0",
              }}
            >
              <Typography
                sx={{ fontSize: "18px", coloe: "#242437", fontWeight: "600" }}
              >
                Total Jobs
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2.5,
                }}
              >
                <Button
                  variant="text"
                  onClick={() => handleNavigation("/dashboard?data=Jobs")}
                  sx={{ textTransform: "capitalize" }}
                >
                  Full Details
                </Button>
                <TimeSelector
                  initialValue={jobsFilterOption}
                  handleFilters={handleTimeFilters}
                />
              </Box>
            </Box>
            <Box
              sx={{
                px: 3.75,
                py: 2.5,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {loading.jobs ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {" "}
                  <Box sx={{ height: "34vh" }}>
                    {!jobCount?.inactiveJobs?.totalInactiveJobs &&
                      !jobCount?.activeJobs?.totalActiveJobs ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          height: "100%",
                          width: "100%",
                          color: "#ccc",
                          pt: 4,
                        }}
                      >
                        <DataUsageOutlinedIcon
                          sx={{
                            fontSize: "18vh",
                          }}
                        />
                        <Typography sx={{ fontSize: "1.6vh" }}>
                          No Data Available
                        </Typography>
                      </Box>
                    ) : (
                      <SunburstChart jobCount={jobCount} />
                    )}
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",

                      }}
                    >
                      <CountText
                        color="#4AEC52"
                        highlightCount={jobCount?.activeJobs?.newJobs}
                        totalCount={jobCount?.activeJobs?.totalActiveJobs}
                        highlightedText="New Jobs"
                        simpleText="Active Jobs"
                        countBoxWidth={"50%"}
                      />
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                      <CountText
                        color="#A9E4AB"
                        highlightCount={jobCount?.activeJobs?.existingJobs}
                        totalCount={jobCount?.activeJobs?.totalActiveJobs}
                        highlightedText="Existing Jobs"
                        simpleText="Active Jobs"
                        itemPlacement={"right"}
                        countBoxWidth={"50%"}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        pt: 2.5,
                      }}
                    >
                      <CountText
                        color="#3F95EA"
                        highlightCount={jobCount?.inactiveJobs?.cancelledJobs}
                        totalCount={jobCount?.inactiveJobs?.totalInactiveJobs}
                        highlightedText="Cancelled Jobs"
                        simpleText="Inactive Jobs"
                        countBoxWidth={"50%"}
                      />
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                      <CountText
                        color="#99C4E2"
                        highlightCount={jobCount?.inactiveJobs?.closedJobs}
                        totalCount={jobCount?.inactiveJobs?.totalInactiveJobs}
                        highlightedText="Closed Jobs"
                        simpleText="Inactive Jobs"
                        itemPlacement={"right"}
                        countBoxWidth={"50%"}
                      />
                    </Box>

                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              background: "#FFF",
              width: "100%",
              borderRadius: 2.5,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "#F9F9F9",
                px: 2.5,
                py: 1.25,
                borderRadius: "10px 10px 0 0",
              }}
            >
              <Typography
                sx={{ fontSize: "18px", coloe: "#242437", fontWeight: "600" }}
              >
                Total Candidates
              </Typography>
              <Button
                onClick={() => handleNavigation("/dashboard?data=Candidates")}
                variant="text"
                sx={{ textTransform: "capitalize" }}
              >
                Full Details
              </Button>
            </Box>
            <Box
              sx={{
                px: 3.75,
                py: 2.5,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {loading.candidates ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {" "}
                  <Box sx={{ height: "30vh" }}>
                    {!candidatesCount?.employerPortal?.associatedWithJob &&
                      !candidatesCount?.employerPortal?.notAssociatedWithJob &&
                      !candidatesCount?.jobezePortal ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          height: "100%",
                          width: "100%",
                          color: "#ccc",
                          pt: 2,
                        }}
                      >
                        <InsertChartOutlinedTwoToneIcon
                          sx={{ fontSize: "18vh", transform: "rotate(90deg)" }}
                        />
                        <Typography sx={{ fontSize: "1.6vh" }}>
                          No Data Available
                        </Typography>
                      </Box>
                    ) : (
                      <BarChart candidatesCount={candidatesCount} />
                    )}
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        pb: 2.5,
                      }}
                    >
                      <CountText
                        color="#3F95EA"
                        highlightCount={
                          candidatesCount?.employerPortal?.associatedWithJob
                        }
                        totalCount={
                          candidatesCount?.employerPortal?.associatedWithJob +
                          candidatesCount?.employerPortal?.notAssociatedWithJob
                        }
                        simpleText="Associated with Job"
                        lastText="Employer Portal"
                        countBoxWidth={"50%"}
                      />
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                      <CountText
                        color="rgba(63, 149, 234, 0.60)"
                        highlightCount={
                          candidatesCount?.employerPortal?.notAssociatedWithJob
                        }
                        totalCount={
                          candidatesCount?.employerPortal?.associatedWithJob +
                          candidatesCount?.employerPortal?.notAssociatedWithJob
                        }
                        simpleText="Not Associated with Job"
                        lastText="Employer Portal"
                        itemPlacement={"right"}
                        countBoxWidth={"50%"}
                      />
                    </Box>
                    <CountText
                      color="#006CB7"
                      highlightCount={candidatesCount?.jobezePortal}
                      simpleText="Associated with Job"
                      lastText="Jobeze Portal"
                      itemPlacement={"center"}
                      countBoxWidth={"100%"}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              background: "#FFF",
              width: "100%",
              borderRadius: 2.5,
              display: "flex",
              flexDirection: "column",
              gap: 2.5,
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                coloe: "#242437",
                fontWeight: "600",
                background: "#F9F9F9",
                px: 2.5,
                py: 1.85,
                borderRadius: "10px 10px 0 0",
              }}
            >
              Recent Activities
            </Typography>
            {loading.activities ? (
              <Box
                sx={{
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                  height: "calc(30vh + 159px)",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  height: "calc(30vh + 140px)",
                  width: "100%",
                  px: 2.5,
                  overflowY: "auto",
                  position: "relative",
                }}
              >
                <Activities activityData={activities} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MyDashboard;
