import React, { useEffect, useState } from "react";
import Header from "../components/header";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useNavigate, useSearchParams } from "react-router-dom";
import BasicInfo from "../components/userAddtabs/basicInfo";
import MenuPermission from "../components/userAddtabs/menuPermission";
import Companyselection from "../components/userAddtabs/companyselection";
import BranchSelection from "../components/userAddtabs/branchSelection";
import axios from "axios";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import UserDetailsPreview from "./userDetailsPreview";
import PermissionUI from "../components/userAddtabs/permissionUI";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3, px: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserAdditon = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openPreview, setOpenPreview] = useState(false);

  //Basic Info
  const [formErrors, setFormErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  //Role based permission
  const [role, setRole] = useState({});
  const [selectedAdmin, setSelectedAdmin] = useState({});
  const [MenuPermissionerror, setMenuPermissionerror] = useState({
    roleError: false,
    adminError: false,
  });

  const handlePreviewChange = () => {
    setOpenPreview(false);
  };

  // user_id
  const user_id = useSelector((state) => state.user.user_id);

  // menu perrmission
  const [permissions, setPermissions] = useState({
    company: "addEdit",
    branch: "addEdit",
    job: "addEdit",
    candidate: "addEdit",
  });

  const [company, setCompany] = useState([]);
  const [companyRadioValue, setCompanyRadioValue] = useState("all");
  const [selectedCompanies, setSelectedCompanies] = useState([-100]);
  const [companySelectError, setCompanySelectError] = useState(false);

  //Branch Tab
  const [branch, setBranch] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [branchRadioValue, setBranchRadioValue] = useState("");
  const [branchSelectError, setBranchSelectError] = useState(
    new Array(
      selectedBranches.filter(
        (branch) => branch?.is_branch_managed === 1
      ).length
    ).fill(false)
  );
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarmessage, setSnackbarmessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [branchMessage, setbranchMessage] = useState("");
  const handlePermissionChange = (event, category) => {
    setPermissions({
      ...permissions,
      [category]: event.target.value,
    });
  };

  const getCompanyLists = async () => {
    try {
      const response = await axios.post(
        "/list-of-companies",
        { under_assigned_user_id: selectedAdmin?.id },
        {
          headers: {
            Authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      );

      const companies = response.data.message.companyListResponse;
      setCompany(companies);
      // Log search parameters to verify their values
      if (
        (searchParams.get("edit") === null ||
          searchParams.get("edit") === "1") &&
        companyRadioValue === "all"
      ) {
        const transformCompaniesToBranches = companies.map((company) => {
          const branchIndex = selectedBranches.findIndex(
            (branch) => branch.company_id === company.id
          );
          if (branchIndex === -1) {
            return {
              company_id: company.id,
              company_name: company.name,
              branches: company.is_branch_managed === 1 ? [-100] : [],
              is_branch_managed: company.is_branch_managed,
            };
          } else {
            return selectedBranches[branchIndex];
          }
        });

        // console.log(transformCompaniesToBranches, "transformCompaniesToBranches");
        setSelectedBranches(transformCompaniesToBranches);
      } else {
        console.log("Condition not met for transformation");
      }
      // Check conditions
      const hasBranch = userData.companiesBranchList.length === 0;
      const companyId = userData.companiesList[0]?.id;
      const company = companies.find((c) => c.id === companyId);
      const isBranchManaged = company ? company.is_branch_managed === 1 : false;

      // Prepare message
      let message = "";
      if (hasBranch && isBranchManaged) {
        message =
          "Selected assign user under does not have access to the branch in this company.";
      }
      setbranchMessage(message);
    } catch (error) {
      console.error("Error fetching company lists:", error);
    }
  };

  useEffect(() => {
    if (selectedAdmin && Object.keys(selectedAdmin).length > 0) {
      getCompanyLists();
    }
  }, [selectedAdmin]);

  const handleInputChane = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" }); // Clear error on change
  };

  const handleFocus = (e) => {
    const { name } = e.target;
    setFormErrors({ ...formErrors, [name]: "" }); // Clear error on focus
  };

  const validate = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formValues.firstName) errors.firstName = "First Name is required";
    // if (!formValues.lastName) errors.lastName = "Last Name is required";
    if (!formValues.email) {
      errors.email = "Email is required";
    } else if (!emailRegex.test(formValues.email)) {
      errors.email = "Email is invalid";
    }
    if (!user_id) {
      if (!formValues.password) {
        errors.password = "Password is required";
      } else if (formValues.password.length < 6) {
        errors.password = "Password must be at least 6 characters long";
      }
      if (!formValues.confirmPassword) {
        errors.confirmPassword = "Confirm Password is required";
      } else if (formValues.password !== formValues.confirmPassword) {
        errors.confirmPassword = "Passwords do not match";
      }
    }
    return errors;
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  //menu permission
  const accessType = {
    200: {
      add: permissions.company === "addEdit",
      edit: permissions.company === "addEdit",
      view: true,
    },
    500: {
      add: permissions.branch === "addEdit",
      edit: permissions.branch === "addEdit",
      view: true,
    },
    400: {
      add: permissions.job === "addEdit",
      edit: permissions.job === "addEdit",
      view: true,
    },
    100: {
      add: permissions.candidate === "addEdit",
      edit: permissions.candidate === "addEdit",
      view: true,
    },
  };

  //company Tab

  const handleCompanyChange = (e) => {
    const { value } = e.target;
    const selectedids = selectedCompanies.map((company) => company.id);
    const newCompany = value.filter(
      (company) => !selectedids.includes(company.id)
    );
    setSelectedCompanies([...selectedCompanies, ...newCompany]);

    // const newSelection = value.filter(company =>
    //   !selectedCompanies.some(selected => selected.id === company.id)
    // );
    // setSelectedCompanies([...selectedCompanies, ...newSelection]);

    if (value.length > 0) {
      setCompanySelectError(false);
      // const filterCompanieswithBranches = value.filter(company => company.is_branch_managed === 1);
      if (companyRadioValue === "select") {
        const transformCompaniesToBranches = value.map((company, index) => {
          if (
            selectedBranches.findIndex(
              (branch) => branch.company_id === company.id
            ) === -1
          ) {
            return {
              company_id: company?.id,
              company_name: company?.name,
              branches: company.is_branch_managed === 1 ? [-100] : [],
              is_branch_managed: company?.is_branch_managed,
            };
          } else {
            return selectedBranches.find(
              (branch) => branch.company_id === company.id
            );
          }
        });
        setSelectedBranches(transformCompaniesToBranches);
      }
    }
  };

  const handleCompanyRadioChange = (event) => {
    setCompanyRadioValue(event.target.value);
    if (event.target.value === "select") {
      setSelectedCompanies([]);
      // const transformCompaniesToBranches = company.map(company => ({
      //   company_id: company.id,
      //   company_name: company.name,
      //   branches: company.is_branch_managed === 1 ? [-100] : [],
      //   is_branch_managed: company.is_branch_managed
      // }));
      setCompanySelectError(false);
      setSelectedBranches([]);
    }
    if (event.target.value === "all") {
      setSelectedCompanies([-100]);
      // const filterCompanieswithBranches = company.filter(company => company.is_branch_managed === 1);
      const transformCompaniesToBranches = company.map((company) => ({
        company_id: company.id,
        company_name: company.name,
        branches: company.is_branch_managed === 1 ? [-100] : [],
        is_branch_managed: company.is_branch_managed,
      }));
      setSelectedBranches(transformCompaniesToBranches);
    }

    if (companyRadioValue === "select") {
      const transformCompaniesToBranches = company.map((company) => ({
        company_id: company.id,
        company_name: company.name,
        branches: company.is_branch_managed === 1 ? [-100] : [],
        is_branch_managed: company.is_branch_managed,
      }));

      setSelectedBranches(transformCompaniesToBranches);
    }
  };

  const handleDeleteCompany = (companyToDelete) => {
    const updatedSelectedCompanies = selectedCompanies.filter(
      (company) => company.id !== companyToDelete.id
    );
    setSelectedCompanies(updatedSelectedCompanies);
    const updateSelectedBranches = selectedBranches.filter(
      (branch) => branch.company_id !== companyToDelete.id
    );
    setSelectedBranches(updateSelectedBranches);
  };

  const handleCompanySelectionError = () => {
    if (companyRadioValue === "select" && selectedCompanies.length === 0) {
      setCompanySelectError(true);
    } else {
      setCompanySelectError(false);
    }
    // return;
  };

  //branch Tab

  const handleBranchChange = (e, index) => {
    const { value } = e.target; // Assuming e.target.value is the array of selected branches

    // Get the current selected branch IDs for the specific index
    const selectedBranchIds = selectedBranches[index].branches.map((branch) =>
      Number(branch.id)
    );

    // Filter out branches that are already in the selectedBranchIds
    const newBranches = value.filter(
      (branch) => !selectedBranchIds.includes(Number(branch.id))
    );

    // Update the selected branches for the specific index
    const updatedBranches = [
      ...selectedBranches[index].branches,
      ...newBranches,
    ];

    // Update the state with the new branches
    const updatedSelectedBranches = [...selectedBranches];
    updatedSelectedBranches[index].branches = updatedBranches;

    setSelectedBranches(updatedSelectedBranches);
    // handleBranchSelectionError(index);

    //ashishji wala code
    // const updatedBranch = [...selectedBranches];
    // updatedBranch[index] = {
    //   ...updatedBranch[index],
    //   branches: [...updatedBranch[index].branches, ...e.target.value]
    // };
    // setSelectedBranches(updatedBranch);
    // handleBranchSelectionError(index);
  };

  const handleBranchRadioChange = (event, index, bvalue) => {
    const updateSelectedBranches = [...selectedBranches];
    updateSelectedBranches[index] = {
      ...updateSelectedBranches[index],
      branches: bvalue.length > 0 && bvalue[0] === -100 ? [] : [-100],
    };
    setSelectedBranches(updateSelectedBranches);
    // handleBranchSelectionError(index);
  };

  const handleDeleteBranch = (index, idx) => {
    // const updatedBranches = [...selectedBranches];
    // updatedBranches[index] = {
    //   ...updatedBranches[index],
    //   branches: updatedBranches[index].branches.filter((branch, dIndex) => dIndex !== idx),
    // };
    // setSelectedBranches(updatedBranches);
    const newSelectedBranches = [...selectedBranches];
    newSelectedBranches[index].branches.splice(idx, 1);
    setSelectedBranches(newSelectedBranches);
    // handleBranchSelectionError(index);
  };

  function handleBranchSelectionError(index) {
    const errors = [...branchSelectError];
    if (selectedBranches[index].branches.includes(-100)) {
      errors[index] = true;
    } else {
      errors[index] = false;
    }
    setBranchSelectError(errors);
  }

  const modifiedSelectedBranchPayload =
    selectedBranches.length < 1
      ? []
      : selectedBranches.map((branch) => {
          const { company_name, is_branch_managed, ...rest } = branch;
          return {
            ...rest,
            branches:
              branch?.branches?.length === 0
                ? [-100]
                : !branch?.branches?.includes(-100)
                ? branch?.branches?.map((bid) => bid.id)
                : branch?.branches,
          };
        });

  const modifiedSelectedCompanyPayload = selectedCompanies.map((company) =>
    company.id ? company.id : -100
  );

  // fetchData

  const [userData, setUserData] = useState([]);

  const fetchUserdata = async () => {
    if (!user_id) {
      return;
    }
    try {
      const response = await axios.get(`/user-info/${user_id}`, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });
      setUserData(response.data.message);
      const { access_type } = response.data.message;
      setFormValues({ ...formValues, ...response.data.message.basic_info });
      const role_name = {
        id: response.data.message?.basic_info?.role_id,
        name: response.data.message?.basic_info?.role_name,
      };
      setRole(role_name);
      const selectedAuthorizename = {
        id: response.data.message?.basic_info?.under_assigned_user_id,
        name: response.data.message?.basic_info?.under_assigned_user_name,
      };
      setSelectedAdmin(selectedAuthorizename);
      // setPermissions({
      //   company:
      //     access_type[200]?.add || access_type[200]?.edit
      //       ? "addEdit"
      //       : "viewOnly",
      //   branch:
      //     access_type[500]?.add || access_type[500]?.edit
      //       ? "addEdit"
      //       : "viewOnly",
      //   job:
      //     access_type[400]?.add || access_type[400]?.edit
      //       ? "addEdit"
      //       : "viewOnly",
      //   candidate:
      //     access_type[100]?.add || access_type[100]?.edit
      //       ? "addEdit"
      //       : "viewOnly",
      // });
      // console.log(
      //   response.data.message?.basic_info?.has_full_company_access,
      //   "has full access "
      // );
      setCompanyRadioValue(
        response.data.message?.basic_info?.has_full_company_access === 1
          ? "all"
          : "select"
      );
      setSelectedCompanies(response.data.message.companiesList);
      // const prevSelectedBranch = response.data.message.companiesBranchList.length < 1 ? [] : response.data.message.companiesBranchList.map((branch) => branch.branches);
      // console.log("selectedBranches", selectedBranches);
      setSelectedBranches(response.data.message.companiesBranchList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserdata();
  }, [user_id]);

  // payload data for the create user
  const payload = {
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    email: formValues.email,
    password: formValues.password,
    // access_type: accessType,
    user_role_id: role?.id,
    under_assigned_user_id: selectedAdmin?.id,
    companiesList: modifiedSelectedCompanyPayload,
    companiesBranchList: modifiedSelectedBranchPayload,
  };

  //payload for the update user
  //update user - post request

  // console.log(formValues, "formValues");
  const UpdateUser = {
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    email: formValues.email,
    original_date_modified: formValues.original_date_modified,
    // access_type: accessType,
    user_role_id: role?.id,
    under_assigned_user_id: selectedAdmin?.id,
    companiesList: modifiedSelectedCompanyPayload,
    companiesBranchList: modifiedSelectedBranchPayload,
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSubmitted(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleSnackbarClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  //create user - post request
  const createUser = async () => {
    try {
      setLoading(true);
      const response = await axios.post("/create-user", payload, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });
      if (response.data.status === 200) {
        setSnackbarmessage(response.data.message.msg);
        setSubmitted(true);
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        setTimeout(() => {
          setLoading(false);
          navigate("/Users?page=1");
        }, 1500);
      }
    } catch (error) {
      setSnackbarmessage(error.response.data.error);
      setSnackbarSeverity("error");
      setLoading(false);
      console.log(error);
    }
  };

  const updateUser = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`/edit-user/${user_id}`, UpdateUser, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });
      if (response.data.status === 200) {
        setSnackbarmessage(response.data.message);
        setSnackbarSeverity("success");
        setSubmitted(true);
        setOpenSnackbar(true);
        setTimeout(() => {
          setLoading(false);
          navigate(`/UsersDetails/${user_id}?tab=b_info`);
        }, 1500);
      }
    } catch (error) {
      // setLoading(true);
      setLoading(false);
      setSnackbarmessage(error.response.data.error);
      setSnackbarSeverity("error");
      setTimeout(() => {
        // setLoading(false);
        setOpenPreview(false);
        setSubmitted(false);
        setOpenSnackbar(false);
      }, 2500);

      console.log(error);
    }
  };

  //handle Submit open preview page
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate();
    setFormErrors(errors);

    //navigate to basic infor when errors
    if (Object.keys(errors).length > 0) {
      setOpenPreview(false);
      setLoading(false);
      if (
        errors.firstName ||
        errors.email ||
        errors.password ||
        errors.confirmPassword
      ) {
        if (user_id) {
          setSearchParams({ tab: "basicInfo", user_id, edit: 1 });
        } else {
          setSearchParams({ tab: "basicInfo" });
        }
        return;
      }
    }

    // Check if either selectedAdmin or role is an empty array or object
    if (
      (Array.isArray(role) && role.length === 0) ||
      (typeof role === "object" && Object.keys(role).length === 0)
    ) {
      setOpenPreview(false);
      setLoading(false);
      setMenuPermissionerror({
        ...MenuPermissionerror,
        roleError: true,
      });
      if (user_id) {
        setSearchParams({ tab: "role_permissions", user_id, edit: 1 });
      } else {
        setSearchParams({ tab: "role_permissions" });
      }
      return;
    }
    // navigate to role permission
    // Check if either selectedAdmin or role is an empty array or object
    if (
      (Array.isArray(selectedAdmin) && selectedAdmin.length === 0) ||
      (typeof selectedAdmin === "object" &&
        Object.keys(selectedAdmin).length === 0)
    ) {
      setOpenPreview(false);
      setLoading(false);
      setMenuPermissionerror({
        ...MenuPermissionerror,
        adminError: true,
      });
      if (user_id) {
        setSearchParams({ tab: "role_permissions", user_id, edit: 1 });
      } else {
        setSearchParams({ tab: "role_permissions" });
      }
      return;
    }

    //navigate to company when errors
    if (companyRadioValue === "select" && selectedCompanies.length === 0) {
      setCompanySelectError(true);
      if (user_id) {
        setSearchParams({ tab: "company", user_id, edit: 1 });
      } else {
        setSearchParams({ tab: "company" });
      }
      return;
    } else {
      setCompanySelectError(false);
    }

    //navigate to branch when errors
    let branchErrors = false;
    const branchSelectErrors = [...branchSelectError];
    selectedBranches.forEach((selectedbranch, index) => {
      if (
        selectedbranch.is_branch_managed === 1 &&
        selectedbranch.branches.length < 1
      ) {
        branchSelectErrors[index] = true;
        branchErrors = true;
      } else {
        branchSelectErrors[index] = false;
      }
    });
    setBranchSelectError(branchSelectErrors);
    if (branchErrors) {
      if (user_id) {
        setSearchParams({ tab: "branch", user_id, edit: 1 });
      } else {
        setSearchParams({ tab: "branch" });
      }
      return;
    }

    //navigate to preview page
    if (Object.keys(errors).length === 0) {
      setOpenPreview(true);
    } else {
      setOpenPreview(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    const tab = searchParams.get("tab");
    const roleArray = role && typeof role === "object" ? Object.keys(role) : [];
    const selectedAdminArray =
      selectedAdmin && typeof selectedAdmin === "object"
        ? Object.keys(selectedAdmin)
        : [];
    if (tab === "basicInfo") {
      setValue(0);
    } else if (tab === "role_permissions") {
      setValue(1);
    } else if (tab === "company") {
      if (roleArray.length === 0 && selectedAdminArray.length === 0) {
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        setSnackbarmessage("Please select role and admin");
        setValue(1);
        setMenuPermissionerror({
          ...MenuPermissionerror,
          adminError: true,
          roleError: true,
        });

        if (user_id) {
          setSearchParams({ tab: "role_permissions", user_id, edit: 1 });
        } else {
          setSearchParams({ tab: "role_permissions" });
        }
      } else {
        setValue(2);
      }
    } else if (tab === "branch") {
      if (roleArray.length === 0 && selectedAdminArray.length === 0) {
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        setSnackbarmessage("Please select role and admin");
        setValue(1);
        setMenuPermissionerror({
          ...MenuPermissionerror,
          adminError: true,
          roleError: true,
        });

        if (user_id) {
          setSearchParams({ tab: "role_permissions", user_id, edit: 1 });
        } else {
          setSearchParams({ tab: "role_permissions" });
        }
      } else {
        setValue(3);
      }
    }
  }, [searchParams, role, selectedAdmin, user_id]);

  const [isCompanyTab, setIsCompanyTab] = useState(false);
  const handleChange = (event, newValue) => {
    // if (selectedBranches.length < 1 && newValue === 3 ) {
    //   if (user_id) {
    //     setSearchParams({ tab: 'company', user_id, edit: 1 });
    //     setCompanySelectError(true);
    //     // setOpenSnackbar(true);
    //     // setSnackbarSeverity("error");
    //     // setSnackbarmessage("Please select at least one company");

    //   } else {
    //     setSearchParams({ tab: 'company' });
    //     setCompanySelectError(true);
    //     // setOpenSnackbar(true);
    //     // setSnackbarSeverity("error");
    //     // setSnackbarmessage("Please select at least one company");

    //   }
    //   return;
    // }

    if (newValue === 3 && selectedCompanies.length === 0) {
      if (user_id) {
        setSearchParams({ tab: "company", user_id, edit: 1 });
        setCompanySelectError(true);
      } else {
        setSearchParams({ tab: "company" });
        setCompanySelectError(true);
      }
      return;
    }
    setValue(newValue);

    const newTab =
      newValue === 0
        ? "basicInfo"
        : newValue === 1
        ? "role_permissions"
        : newValue === 2
        ? "company"
        : "branch";

    if (user_id) {
      setSearchParams({ tab: newTab, user_id, edit: 1 });
    } else {
      setSearchParams({ tab: newTab });
    }
  };
  useEffect(() => {
    setValue(0);
    handleChange(0, 0);
    setMenuPermissionerror({
      ...MenuPermissionerror,
      adminError: false,
      roleError: false,
    });
  }, []); //when ever the page refreshed it should be start from basic info
  return (
    <Box
      className="pageWrapper"
      style={{ backgroundColor: "rgba(242, 248, 255, 1)" }}
    >
      <Box>
        <Box>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box item sx={{ width: "100%" }}>
              <Box
                flex={1}
                component={"div"}
                sx={{
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: 5,
                  backgroundColor: "rgba(255, 255, 255, 1)",
                }}
              >
                <Box>
                  <Typography
                    sx={{ p: 2, px: 3 }}
                    fontWeight={500}
                    fontSize={"22px"}
                    variant="body1"
                  >
                    {user_id ? "Edit User" : "Add User"}
                  </Typography>
                  <Divider />
                </Box>
                <Box sx={{ mt: 3, px: 3 }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        label="Basic Info"
                        sx={{ letterSpacing: "1.25px", px: 4 }}
                        {...a11yProps(0)}
                      />
                      <Tab
                        label="Role Permissions"
                        sx={{ letterSpacing: "1.25px", px: 4 }}
                        {...a11yProps(1)}
                      />
                      <Tab
                        label="Company"
                        sx={{ letterSpacing: "1.25px", px: 4 }}
                        {...a11yProps(2)}
                      />
                      <Tab
                        label="Branch"
                        sx={{ letterSpacing: "1.25px", px: 4 }}
                        {...a11yProps(3)}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <BasicInfo
                      formErrors={formErrors}
                      formValues={formValues}
                      handleInputChane={handleInputChane}
                      handleFocus={handleFocus}
                      handleClickShowConfirmPassword={
                        handleClickShowConfirmPassword
                      }
                      handleClickShowPassword={handleClickShowPassword}
                      showPassword={showPassword}
                      showConfirmPassword={showConfirmPassword}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    {/* <MenuPermission
                      permissions={permissions}
                      handlePermissionChange={handlePermissionChange}
                    /> */}
                    {
                      <PermissionUI
                        role={role}
                        selectedAdmin={selectedAdmin}
                        setSelectedAdmin={setSelectedAdmin}
                        setRole={setRole}
                        MenuPermissionerror={MenuPermissionerror}
                        setMenuPermissionerror={setMenuPermissionerror}
                      />
                    }
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <Companyselection
                      company={company}
                      // selectedAdmin={selectedAdmin?.id}
                      // setCompany={setCompany}
                      selectedCompanies={selectedCompanies}
                      // selectedBranches={selectedBranches}
                      setSelectedCompanies={setSelectedCompanies}
                      handleCompanyChange={handleCompanyChange}
                      companyRadioValue={companyRadioValue}
                      // isCompanyTab={isCompanyTab}
                      setIsCompanyTab={setIsCompanyTab}
                      // setCompanyRadioValue={setCompanyRadioValue}
                      handleCompanyRadioChange={handleCompanyRadioChange}
                      handleDeleteCompany={handleDeleteCompany}
                      handleCompanySelectionError={handleCompanySelectionError}
                      companySelectError={companySelectError}
                      // setSelectedBranches={setSelectedBranches}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={3}>
                    <BranchSelection
                      branch={branch}
                      branchMessage={branchMessage}
                      selectedAdmin={selectedAdmin?.id}
                      setBranch={setBranch}
                      selectedBranches={selectedBranches}
                      setSelectedBranches={setSelectedBranches}
                      handleBranchChange={handleBranchChange}
                      branchRadioValue={branchRadioValue}
                      setBranchRadioValue={setBranchRadioValue}
                      handleBranchRadioChange={handleBranchRadioChange}
                      handleDeleteBranch={handleDeleteBranch}
                      handleBranchSelectionError={handleBranchSelectionError}
                      branchSelectError={branchSelectError}
                    />
                  </CustomTabPanel>
                </Box>
                <Box>
                  <Divider />
                </Box>
                <Box p={2}>
                  <Typography
                    display={"flex"}
                    justifyContent={"end"}
                    gap={2}
                    alignItems={"center"}
                  >
                    <Button
                      variant="text"
                      onClick={() => {
                        if (user_id) {
                          navigate(`/UsersDetails/${user_id}?tab=b_info`);
                        } else {
                          navigate(`/Users?page=1`);
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    {/* <Button variant="contained" onClick={() => navigate('/UserPreview')} >Preview</Button> */}
                    <Button variant="contained" onClick={handleSubmit}>
                      Preview
                    </Button>
                    <Backdrop
                      sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 9999999999,
                      }}
                      open={loading}
                      // onClick={handleClose}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Grid
              item
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              gap={"20px"}
              width={{ xs: "100%", sm: "100%", md: "55%", lg: "55%" }}
              sx={{
                borderRadius: 5,
                height: "100%",
                position: "sticky",
                top: "5%",
                right: 0,
                mb: { xs: 2, sm: 3, md: 4, lg: 8 },
              }}
            >
              <Box
                sx={{
                  py: 3,
                  px: 3,
                  backgroundColor: "#FFF3E0",
                  borderRadius: "16px",
                }}
              >
                <Typography fontSize={"34px"} align="left">
                  <img src="./brake-warning.png" />
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  fontSize={"20px"}
                >
                  Importance
                </Typography>
                <Typography variant="body1" sx={{ pt: 2.5 }}>
                  Users represent individuals within a company who are
                  authorized to post job listings, manage applications, or
                  perform other administrative tasks on behalf of the company.
                </Typography>
              </Box>
              <Box
                sx={{
                  py: 2,
                  px: 3,
                  backgroundColor: "#FFF3E0",
                  borderRadius: "16px",
                }}
              >
                <Typography fontSize={"34px"} align="left">
                  💡
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  fontSize={"20px"}
                  // sx={{ pt: 0.5 }}
                >
                  Tips
                </Typography>
                <Typography variant="body1" sx={{ pl: 2, pt: 2 }}>
                  <ul
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <li>
                      Offer different user roles with varying levels of access
                      and permissions, such as admin, recruiter, or manager.
                    </li>
                    <li>
                      Implement a user invitation system where company admins
                      can invite team members to join the platform under their
                      company’s account.
                    </li>
                    <li>
                      Provide a user management interface where company admins
                      can add, remove, or update user accounts as needed.
                    </li>
                  </ul>
                </Typography>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Snackbar open={openSnackbar} anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000} >
        <Alert onClose={handleSnackbarClose} elevation={6} severity={snackbarSeverity === "error" ? "error" : "success"}>
          {snackbarmessage}
        </Alert>
      </Snackbar> */}
      {openPreview && (
        <UserDetailsPreview
          branchMessage={branchMessage}
          formValues={formValues}
          role={role?.name}
          selectedAdmin={selectedAdmin?.name}
          openPreview={openPreview}
          handlePreviewChange={handlePreviewChange}
          accessType={accessType}
          user_id={user_id}
          // openSnackbar={openSnackbar}
          submitted={submitted}
          selectedCompanies={selectedCompanies}
          selectedBranches={selectedBranches}
          setSubmitted={setSubmitted}
          action={action}
          snackbarSeverity={snackbarSeverity}
          snackbarmessage={snackbarmessage}
          handleSnackbarClose={handleSnackbarClose}
          updateUser={updateUser}
          createUser={createUser}
        />
      )}
    </Box>
  );
};

export default UserAdditon;
