import {
  Box,
  Chip,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { convert } from "html-to-text";
import Cookies from "js-cookie";

// const baseURL = process.env.REACT_APP_BASEURL;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  boxShadow: "none",
  padding: theme.spacing(1),
}));

const myTypes = {
  H: "Hire",
  C: "Contract",
  C2H: "Contract to Hire",
  F: "Freelance",
};

const questionsType = ["Descriptive", "Numeric", "Yes/No"];

const JobInfo = ({ isDrawerOpen }) => {
  const { id } = useParams();
  const getToken = useSelector((state) => state.user.token);
  const [jobinfo, setJoninfo] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [isQuestionRequierd, setIsQuestionRequierd] = useState(false);
  useEffect(() => {
    axios
      .get(`job-info/${id}`, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      })
      .then((response) => {
        setJoninfo(response.data.message.jobInfoResult[0]);
        setIsQuestionRequierd(
          response.data.message.jobInfoResult[0].questionnaire_required
        );
      })
      .catch((err) => { });
  }, [id]);

  const date = new Date(jobinfo?.date_created).toLocaleDateString();
  let skills = jobinfo?.skills;

  // There is also an alias to `convert` called `htmlToText`.

  const options = {
    wordwrap: 130,
    // ...
  };
  const text = convert(
    jobinfo?.description ? jobinfo?.description : "NA",
    options
  );

  useEffect(() => {
    if (isQuestionRequierd === false || isQuestionRequierd === 0) {
      return;
    }
    axios
      .post(
        `/question-list-job/${id}`,
        { applyFilter: 0 },
        {
          headers: {
            authorization:
              Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
          },
        }
      )
      .then((response) => {
        // console.log("resssss", response.data.message);
        setQuestions(response.data.message);
      })
      .catch((error) => {
        console.error("Error fetching questions for job:", error);
      });
  }, [isQuestionRequierd]);

  return (
    <div>
      <Container maxWidth="xxl">
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5">Description</Typography>
          <Typography variant="body1" maxWidth="lg" sx={{ mt: 1.5, mb: 3 }}>
            <div
              dangerouslySetInnerHTML={{
                __html: jobinfo?.description ? jobinfo?.description : "NA",
              }}
            />
          </Typography>
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Skills & Other Details
          </Typography>
          <Typography variant="body1">
            <Typography
              variant="body1"
              maxWidth={"lg"}
              color={"rgba(0, 0, 0, 0.87)"}
              sx={{ my: 2 }}
              gutterBottom
            >
              Skill(s)
            </Typography>
            <Stack spacing={1}>
              <Stack
                direction="row"
                className="fw-500"
                flexWrap={"wrap"}
                gap={2}
                sx={{ textTransform: "capitalize" }}
              >
                {skills &&
                  skills.map((item) => {
                    return (
                      <Chip
                        label={item}
                        variant="filled"
                        sx={{ backgroundColor: "rgba(0, 0, 0, 0.87))" }}
                      />
                    );
                  })}
              </Stack>
            </Stack>
          </Typography>
        </Box>

        <Box
          sx={{ mb: 2, p: 3, backgroundColor: "#fff", borderRadius: "10px" }}
          maxWidth={"lg"}
        >
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Item sx={{ p: 0 }}>
                <Typography variant="body1" gutterBottom>
                  Job Type
                </Typography>
                <Typography variant="body2" fontWeight={"bold"} gutterBottom>
                  {myTypes[jobinfo?.type] ? myTypes[jobinfo?.type] : "NA"}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{ p: 0 }}>
                <Typography variant="body1" gutterBottom>
                  Job Title
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight={"bold"}
                  sx={{ wordBreak: "break-word", textTransform: "capitalize" }}
                  gutterBottom
                >
                  {jobinfo?.title ? jobinfo?.title : "NA"}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{ p: 0 }}>
                <Typography variant="body1" gutterBottom>
                  Experience (Years)
                </Typography>
                <Typography variant="body2" fontWeight={"bold"} gutterBottom>
                  {jobinfo?.min_experience === 0 && !jobinfo?.max_experience
                    ? "Entry-Level"
                    : jobinfo?.min_experience?.toString()
                      ? `${jobinfo.min_experience}${!jobinfo.max_experience ? "+" : ""
                      }`
                      : ""}
                  {jobinfo?.max_experience ? " - " : ""}
                  {jobinfo?.max_experience ? jobinfo?.max_experience : ""}
                  {jobinfo?.min_experience == 1 && jobinfo?.max_experience == ""
                    ? " Years"
                    : jobinfo?.min_experience || jobinfo?.max_experience
                      ? " Years"
                      : ""}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{ p: 0 }}>
                <Typography variant="body1" gutterBottom>
                  Category
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ wordBreak: "break-word" }}
                  fontWeight={"bold"}
                  gutterBottom
                >
                  {jobinfo?.role ? jobinfo?.role : "NA"}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{ p: 0 }}>
                <Typography variant="body1" gutterBottom>
                  Duration
                </Typography>
                <Typography variant="body2" fontWeight={"bold"} gutterBottom>
                  {jobinfo?.duration ? jobinfo?.duration : "NA"}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{ p: 0 }}>
                <Typography variant="body1" gutterBottom>
                  Location
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ wordBreak: "break-word" }}
                  fontWeight={"bold"}
                  gutterBottom
                >
                  {jobinfo?.city ? jobinfo?.city : "NA"},{" "}
                  {jobinfo?.state ? jobinfo?.state : "NA"}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{ p: 0 }}>
                <Typography variant="body1" gutterBottom>
                  Start Date
                </Typography>
                <Typography variant="body2" fontWeight={"bold"} gutterBottom>
                  {date ? date : "NA"}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{ p: 0 }}>
                <Typography variant="body1" gutterBottom>
                  Maximum Rate ($)
                </Typography>
                <Typography variant="body2" fontWeight={"bold"} gutterBottom>
                  {jobinfo?.rate ? jobinfo?.rate : "NA"}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{ p: 0 }}>
                <Typography variant="body1" gutterBottom>
                  Salary ($)
                </Typography>
                <Typography variant="body2" fontWeight={"bold"} gutterBottom>
                  {jobinfo?.salary ? jobinfo.salary : "NA"}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{ p: 0 }}>
                <Typography variant="body1" gutterBottom>
                  Work Mode
                </Typography>
                <Typography variant="body2" fontWeight={"bold"} gutterBottom>
                  {jobinfo?.workmode ? jobinfo?.workmode : "NA"}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{ p: 0 }}>
                <Typography variant="body1" gutterBottom>
                  Openings
                </Typography>
                <Typography variant="body2" fontWeight={"bold"} gutterBottom>
                  {jobinfo?.openings ? jobinfo?.openings : "NA"}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{ p: 0 }}>
                {" "}
                <Typography variant="body1" gutterBottom>
                  Cover Letter Required
                </Typography>
                <Typography variant="body2" fontWeight={"bold"} gutterBottom>
                  {jobinfo?.cover_letter_required ? "Yes" : "No"}
                </Typography>
              </Item>
            </Grid>
            {jobinfo?.branch_id && (
              <Grid item xs={4}>
                <Item sx={{ p: 0 }}>
                  <Typography variant="body1" gutterBottom>
                    Branch
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={"bold"}
                    sx={{ textTransform: "capitalize" }}
                    gutterBottom
                  >
                    {jobinfo?.branch_name ? jobinfo?.branch_name : "NA"}
                  </Typography>
                </Item>
              </Grid>
            )}
          </Grid>
        </Box>

        {
          questions.length > 0 && <Box
            sx={{ mb: 4, p: 3, backgroundColor: "#fff", borderRadius: "10px" }}
            maxWidth={"lg"}
          >
            <Typography sx={{ fontSize: "20px" }}>Screening Questions</Typography>
            <ol
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                padding: "12px 0 0 16px",
              }}
            >
              {questions.map((question) => (
                <li key={question.question_id}>
                  <Box>{questionsType[question.question_type - 1]} question.</Box>
                  <Box style={{ fontWeight: "700", mt: 0.5 }}>
                    {question.question}
                  </Box>
                </li>
              ))}
            </ol>
          </Box>
        }



        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Internal Notes
          </Typography>
          <Typography
            variant="body1"
            maxWidth={"lg"}
            sx={{ mt: 1.5, mb: 3, wordBreak: "break-word" }}
          >
            {jobinfo?.notes ? jobinfo?.notes : "NA"}
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default JobInfo;
