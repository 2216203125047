import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CreatedByCheckbox = ({ candidateList, handleSelectionData, selectedOptions, username }) => {
  const location = useLocation();
  // Log the current URL
  console.log("Current URL:", window.location.href);
  const url = window.location.href;
  const isFindTalentsPage = url.includes('/Find-talents');
  return (
    <Box width={"100%"} borderRadius={2}>
      <Autocomplete
        multiple
        fullWidth
        size="small"
        id="checkboxes-tags-demo"
        key={candidateList}
        options={candidateList}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        onChange={handleSelectionData}
        value={selectedOptions || []}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            label={`${username}`}
            placeholder="Search here..."
            sx={{
              // Apply conditional styles
              ...(isFindTalentsPage ? {} : {
                '& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root': {
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "0.5px",
                  color: "#667085",
                },
                borderRadius: '10px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px',
                },
              }),
            }}
          />
        )}
      />
    </Box>
  );
};

export default CreatedByCheckbox;
