import React, { useEffect, useState } from "react";
import Header from "../components/header";
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
// import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import BranchInfo from "../components/branchInfo";
import axios from "axios";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import DeleteJobDialog from "./DeleteJobDialog";
const Item = styled(Paper)(({ theme }) => ({
  boxShadow: "none",
  padding: theme.spacing(0.5),
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const BranchDetails = () => {
  const location = useLocation();
  const { state } = location;

  const { company_id, branch_id } = state || {};
  const navigate = useNavigate();

  // console.log("branch_id", branch_id);
  // console.log("company_id", company_id);

  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [branchInfo, setBranchInfo] = useState([]);
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab === "BranchInfo") {
      window.scrollTo(0, 0);
      setValue(0);
    }
  }, [searchParams]);

  const getCompanyDetails = async () => {
    const response = await axios.get(`/company-info/${company_id}`, {
      headers: {
        authorization:
          Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
      },
    });
    // console.log("comapny response", response.data.message[0]);
    setFileName(response.data.message[0].logo);
  };
  const getBranchInfo = async () => {
    const res = await axios.get(`/branch-info/${branch_id}`, {
      headers: {
        authorization:
          Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
      },
    });
    // console.log("branch info", res.data);
    setBranchInfo(res.data.message[0]);
  };

  useEffect(() => {
    getCompanyDetails();
    getBranchInfo();
  }, [id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchParams({ tab: newValue === 1 ? "BranchInfo" : "companyinfo" });
  };

  const managePermissions = useSelector((state) => state?.user.permissions);

  const editBranchPermission = managePermissions["500"];
  const role = useSelector((state) => state?.user?.role);

  // console.log(branchInfo, "branchInfo");
  // console.log("file name", fileName);

  const IsEmployer = useSelector((state) => state.user.isEmployer);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [Snackbarserverity, setSnackbarServerity] = useState();

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const handleBack = () => {
    const previousUrl = sessionStorage.getItem('BranchpreviousUrl');

    if (previousUrl) {
      // Parse the URL
      const url = new URL(previousUrl, window.location.origin); // Ensure URL is absolute
      const path = url.pathname; // Extract path
      const queryParams = url.search; // Extract query parameters

      // Navigate using path and query parameters
      navigate(`${path}${queryParams}`);

      // Clean up
      sessionStorage.removeItem('BranchpreviousUrl');
    }
    else {
      navigate(`/CompanyDetails/${company_id}?tab=companyBranch&page=1`);
    }
  };
  return (
    <div>
      <Container maxWidth="xxl" sx={{ px: { xs: 1, sm: 2, md: 3, lg: 8 } }}>
        <Box sx={{ flexGrow: 1, mt: 4 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Box onClick={handleBack}>
                <ArrowBackIosIcon sx={{ cursor: "pointer" }} />
              </Box>
              <Box>
                {fileName ? (
                  <Avatar
                    style={{
                      width: 100,
                      height: 100,
                      borderRadius: "6px",
                    }}
                    src={fileName}
                  />
                ) : (
                  <Avatar
                    style={{
                      width: 100,
                      height: 100,
                      borderRadius: "6px",
                    }}
                  />
                )}
              </Box>
              <Box sx={{ px: 3 }}>
                <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                  {branchInfo.name || "NA"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ textTransform: "capitalize" }}
                  color={"gray"}
                >
                  {branchInfo && branchInfo.city && branchInfo.state ? (
                    <>
                      {branchInfo.city}, {branchInfo.state}
                    </>
                  ) : (
                    "NA"
                  )}
                </Typography>
              </Box>
            </Grid>
            {(editBranchPermission.edit === 1 && branchInfo.is_editable === 1) && (
              <Grid item xs={6}>
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  variant="h3"
                  component="h1"
                >
                  {searchParams.get("tab") === "BranchInfo" && (
                    <Box display={"flex"} gap={2} alignItems={"center"}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<EditIcon />}
                      >
                        <Link
                          style={{ textDecoration: "none", color: "inherit" }}
                          to={`/CompanyDetails/${company_id}/addBranch?b_id=${branch_id}&edit=1`}
                          state={{ company_id, branch_id }}
                        >
                          Edit Branch
                        </Link>
                      </Button>
                    </Box>
                  )}
                  {searchParams.get("tab") === "BranchInfo" &&
                    (editBranchPermission?.delete === 1 && branchInfo.is_editable === 1) && (
                      <Box display={"flex"} gap={2} alignItems={"center"}>
                        <Tooltip
                          title={
                            branchInfo.is_internal === 1
                              ? "Internal branch can't be deleted"
                              : ""
                          }
                          disableHoverListener={branchInfo.is_internal !== 1}
                        >
                          <span>
                            <Button
                              onClick={handleOpenDeleteDialog}
                              color="error"
                              variant="outlined"
                              sx={{ ml: 2 }}
                              startIcon={<DeleteOutlineOutlinedIcon />}
                              disabled={branchInfo.is_internal === 1}
                            >
                              Delete Branch
                            </Button>
                          </span>
                        </Tooltip>
                        <DeleteJobDialog
                          Deletename={branchInfo.name}
                          id={branch_id}
                          open={openDeleteDialog}
                          handleClose={handleCloseDeleteDialog}
                          setOpenSnackbar={setOpenSnackbar}
                          setSnackbarMessage={setSnackbarMessage}
                          setSnackbarServerity={setSnackbarServerity}
                        />
                      </Box>
                    )}
                </Box>
              </Grid>
            )}
            <Grid item xs={12}></Grid>
          </Grid>
        </Box>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              textColor={value === 2 ? "secondary" : "primary"}
              indicatorColor={value === 2 ? "secondary" : "primary"}
              onChange={handleChange}
              aria-label="icon label tabs example"
            >
              <Tab
                label="Branches Info"
                sx={{ letterSpacing: "1.25px", px: 4 }}
                {...a11yProps(0)}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <BranchInfo branchInfo={branchInfo} />
          </CustomTabPanel>
        </Box>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={Snackbarserverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default BranchDetails;
