import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Cookies from "js-cookie";

const BlogDisplay = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState([]);
  const [openDialog, setOpenDialog] = useState(false); // State to open/close dialog
  // Handle opening the dialog
  const handleDeleteClick = () => {
    setOpenDialog(true); // Open confirmation dialog
  };

  // Handle closing the dialog without doing anything
  const handleCloseDialog = () => {
    setOpenDialog(false); // Close dialog
  };

  // Handle confirming the deletion
  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`/delete-blog/${id}`, {
        headers: {
          authorization:
            Cookies.get("_secure_ARJ_") || Cookies.get("_secure_ref"),
        },
      });
      navigate("/blogs");
    } catch (error) {
      console.error("Error deleting blog:", error);
      // Optionally, show an error message
    } finally {
      setOpenDialog(false); // Close dialog after action
    }
  };
  const getBlog = async () => {
    try {
      const response = await axios.get(`/blog/${id}`);
      setBlog(response.data.message[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getBlog();
  }, []);
  const sanitizeAndStyleHTML = (htmlContent, altTags) => {
    if (!blog && blog.length === 0) {
      return;
    }
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Split the altTags string by commas into an array
    const altTagsArray = altTags?.split(",").slice(1);

    // Get all img elements in the tempDiv
    const images = tempDiv.getElementsByTagName("img");

    // Loop through each img element and set styles and alt attribute
    for (let i = 0; i < images.length; i++) {
      const img = images[i];

      // Set styling
      img.style.maxWidth = "100%";
      img.style.height = "auto";
      img.style.objectFit = "cover";
      img.style.borderRadius = "12px";

      // Set alt attribute from the altTags array if available
      if (altTagsArray[i]) {
        img.alt = altTagsArray[i].trim(); // Trim to remove any extra whitespace
      } else {
        img.alt = "Jobeze - AI-powered job assistant"; // Default alt text if no altTag is provided
      }
    }

    return tempDiv.innerHTML;
  };
  const styledContent = sanitizeAndStyleHTML(blog.content, blog.alt_text);

  return (
    <Box className="pageWrapper">
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant="contained"
          onClick={() => navigate(`/blog/${id}/edit`)}
          sx={{ mr: 1 }}
        >
          Edit Blog
        </Button>
        <Button variant="outlined" color="error" onClick={handleDeleteClick}>
          Delete Blog
        </Button>
      </Box>

      {/* Blog content */}
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          padding: {
            xs: "0px !important",
          },
        }}
      >
        <Card sx={{ boxShadow: "none", backgroundColor: "#fafafa !important" }}>
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              fontSize: { xs: "24px", md: "28px", lg: "28px" },
              fontWeight: "700",
            }}
          >
            {blog.title}
          </Typography>
          <CardMedia
            component="img"
            height="400"
            image={blog.featured_image}
            alt={blog?.alt_text?.split(",", 1)}
            sx={{
              borderRadius: "12px",
              height: "auto",
              objectFit: "cover",
            }}
          />
          <CardContent sx={{ padding: { xs: "0px !important" } }}>
            <Typography
              variant="body1"
              paragraph
              sx={{
                marginTop: "8px",
                fontWeight: "400",
                lineHeight: { xs: "28px", sm: "32px" },
                textAlign: "justify",
              }}
            >
              {blog.description}
            </Typography>
            <div
              style={{ textAlign: "-moz-initial" }}
              dangerouslySetInnerHTML={{ __html: styledContent }}
            />
          </CardContent>
        </Card>
      </Grid>

      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this blog? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BlogDisplay;
